import axios from "axios";
import { showNotification } from "../redux/Notification/NotificationSlice";

const { REACT_APP_BASE_API_URL } = process.env;

const methods = {
  GET: axios.get,
  POST: axios.post,
  DELETE: axios.delete,
  PATCH: axios.patch,
  PUT: axios.put,
};

export const handleRequest = async (
  endpoint,
  requestData,
  toastIdPrefix,
  thunkAPI,
  successCallback = null,
  method = "POST",
  disabledNotification = false
) => {
  try {
    const axiosMethod = methods[method.toUpperCase()];
    if (!axiosMethod) {
      throw new Error(`Invalid HTTP method: ${method}`);
    }
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (requestData instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    }

    const response = await axios({
      method: method,
      url: `${REACT_APP_BASE_API_URL}${endpoint}`,
      data: requestData,
      headers: headers,
    });
    let { data } = response.data;
    if (data == null) {
      data = response.data;
    }

    if (response.status === 200) {
      if (successCallback && data) {
        successCallback(data);
      }
      if (method !== "GET" && !disabledNotification) {
        const { data, status } = response;
        thunkAPI.dispatch(
          showNotification({
            message: data.message,
            status: status,
            isActive: true,
            toastId: `${toastIdPrefix} - Success - ${
              data[toastIdPrefix]?._id || ""
            }`,
          })
        );
      }
      return thunkAPI.fulfillWithValue(data);
    } else {
      thunkAPI.dispatch(
        showNotification({
          message: response.data.message,
          status: response.status,
          isActive: true,
          toastId: `${toastIdPrefix}Failed`,
        })
      );
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    thunkAPI.dispatch(
      showNotification({
        message: error.response.data.message,
        status: error.response.status,
        isActive: true,
        toastId: `${toastIdPrefix}Failed`,
      })
    );

    return thunkAPI.rejectWithValue(error.response);
  }
};

export const isXeroMap = {
  chartOfAccounts: false,
  detailedTransaction: false,
  settings: false,
  budgets: false,
  contacts: false,
  transactions: false,
};

export const checkDataAvailibility = (state) => {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const accFirmData = JSON.parse(localStorage.getItem("accFirm_data"));
  let availableData = {
    name: state.firstName
      ? `${state.firstName} ${state.lastName}`
      : userData.name,
    email: state.email ? state.email : localStorage.getItem("email"),
    imgUrl: state.imgUrl ? state.imgUrl : userData.imgUrl || "",
    role: state.role ? state.role : localStorage.getItem("role"),
    accFirmName: state.accFirmProfile?.name
      ? state.accFirmProfile?.name
      : accFirmData?.accFirmName,
    accFirmImg: state.accFirmProfile?.imgUrl
      ? state.accFirmProfile?.imgUrl
      : accFirmData?.accFirmImg,
  };
  return availableData;
};
