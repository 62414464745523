import React, { Fragment, useEffect, useState, useCallback } from "react";

import {
  Drawer,
  Typography,
  Box,
  Modal,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  styled,
  Switch,
  Chip,
  Tooltip,
  Accordion,
  AccordionSummary,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import _, { cloneDeep } from "lodash";
import SpreadSheetTable from "../SpreadSheetTable";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshing,
  setSpreadsheetDataReference,
  syncManualMetricToXero,
  updateSpreadsheet,
} from "../../../../redux/SpreadSheet/SpreadSheetSlice";

import {
  ROW_TYPE,
  PRIOR_PERRIOD_KEYS,
  DEFAULT_KEYS,
  MONTH_MAP,
  style,
  METRIC_TYPES,
  VALUE_TYPES,
  CALCULATION_TYPES,
  MAPPING_TYPES,
  TIMING_TYPES,
  ACCOUNT_METRIC_TYPES,
  defaultModalValues,
  REFERENCE_TABS,
} from "../defaultValues";
import {
  AccessTime,
  Add,
  AddCircle,
  Close,
  Delete,
  Edit,
  ExpandMore,
  Info,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
  SwapVert,
  Sync,
} from "@mui/icons-material";
import {
  handleValueCalculationsForecastIncluded,
  getTimingRange,
  handleSumRowsHelper,
  getArrayDiff,
  getFormulaSymbol,
  getForecastName,
  handleReferenceUpdate,
} from "../helper";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import { SideNavStyledTab, getRowClass, getRowStyle } from "../SpreadSheet";
import {
  defaultAccountMetricValues,
  defaultForecastValues,
} from "../defaultValues";
import { SortableList } from "../components/SortableVertical/SortableList/SortableList";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "../components/SortableContainer/SortableItem/SortableItem";
import SortableGrid from "../components/SortableContainer/SortableGrid/SortableGrid";
import { Mixpanel } from "../../../../helper/mixpanelHelper";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { ColorPalette } from "../../../../constants/colorPalette";
var ObjectID = require("bson-objectid");
const { RangePicker } = DatePicker;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  marginLeft: 10,
  marginRight: 10,
  marginTop: 2,

  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 14,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(15px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    height: 14,
    marginTop: 1,
    marginLeft: 1,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 300,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
    boxSizing: "border-box",
  },
}));

const defaultExpenseItemValue = {
  metricName: "",
  valueType: VALUE_TYPES[0].value,
  metricType: METRIC_TYPES[0].value,
  calculationType: null,
  accountMapping: null,
  manualAccountMetric: true,
  xeroSynced: false,
  rowType: ROW_TYPE.ACCOUNT,
  subParentId: null,
  isExpanded: false,
};

export default function Expense({
  expenseData,
  entityId,
  spreadSheetId,
  accountingFirmId,
  durationAdded,
  durationData,
  refreshData,
  setTabSwitch,
  nameList,
  spreadSheet,
  setSpreadSheet,
  tabValue,
  currentEntity,
  sideNavDisableList,
}) {
  const dispatch = useDispatch();
  const [accountMapping, setAccountMapping] = useState([]);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [mappingSearch, setMappingSearch] = useState("");
  const { mappingData: expenseMap } = useSelector((state) => state?.expense);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [forecastOpen, setForecastOpen] = useState(false);
  const [accountMetricOpen, setAccountMetricOpen] = useState(false);
  const [uncollapsedMapping, setUnCollapsedMapping] = useState([]);
  const [sideNavData, setSideNavData] = useState(null);
  const [sideNavDataInitial, setSideNavDataInitial] = useState(null);
  const [currentAccountMapping, setCurrentAccountMapping] = useState({});

  const [currentSideNavTab, setCurrentSideNavTab] = useState(0);
  const [currentForecastData, setCurrentForecastData] = useState(null);
  const [currentAccountMetricData, setCurrentAccountMetricData] =
    useState(null);
  const [forecastModalType, setForecastModalType] = useState("add");
  const [accountMetricModalType, setAccountMetricModalType] = useState("add");
  const [forecastMetricSubmitted, setForecastMetricSubmitted] = useState(false);
  const [sideNavOpenId, setSideNavOpenId] = useState(null);
  const [modalValues, setModalValues] = useState(defaultModalValues);
  const [modalOpen, setModalOpen] = useState(false);
  const [formulaEdit, setFormulaEdit] = useState(false);
  const [formulaIdList, setFormulaIdList] = useState([]);
  const [initialMetricData, setInitialMetricData] = useState(null);
  const [currentAccountMetricForecastId, setCurrentAccountMetricForecastId] =
    useState(null);
  const [allowSortOrder, setAllowSortOrder] = useState(false);
  const [allowSortOrderFormula, setAllowSortOrderFormula] = useState(false);
  const [sortItems, setSortItems] = useState([]);
  const [addExpenseItemOpen, setAddExpenseItemOpen] = useState(false);
  const [currentExpenseItemValue, setCurrentExpenseItemValue] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [spreadsheetData, setSpreadsheetData] = useState({
    rows: [],
    columns: [],
    headers: {},
    unCollapsedRows: [],
    value: [],
  });
  //only for reloading the pages for fixing bug of the package
  const [reload, setReload] = useState(false);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    if (sideNavOpenId !== null) {
      handleSideNavOpen();
    }
    // eslint-disable-next-line
  }, [sideNavOpenId]);

  useEffect(() => {
    if (expenseMap) {
      let value = [];
      expenseMap.forEach((data) => {
        data.children.forEach((child) => {
          value.push(child);
          if (child.type === "Account Group") {
            child.children.forEach((subChild) => {
              value.push({ ...subChild, parent: child.id });
            });
          }
        });
      });
      setAccountMapping(value);
    }
  }, [expenseMap]);

  //only for reloading the pages for fixing bug of the package
  useEffect(() => {
    setReload(!reload);
    // eslint-disable-next-line
  }, [spreadsheetData]);

  useEffect(() => {
    if (expenseData && refreshData) {
      let rowData = getRows(
        expenseData?.rows || [],
        expenseData.headers,
        expenseData.unCollapsedRows
      );
      setSpreadsheetData({
        columns: expenseData.columns,
        headers: expenseData.headers,
        rows: rowData,
        value: expenseData?.rows,
        unCollapsedRows: expenseData.unCollapsedRows,
      });
      if (sideNavData) {
        setSideNavData(
          expenseData?.rows?.find((row) =>
            sideNavData?.manualAccountMetric
              ? row.metricName === sideNavData.metricName
              : row.id === sideNavData.id
          )
        );
      }
      setTabSwitch(false);
    }
    // eslint-disable-next-line
  }, [expenseData]);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (over?.id) {
        setFormulaIdList((items) => {
          let oldIndex = items.indexOf(active.id);
          let newIndex = items.indexOf(over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  }, []);

  const handleUpdate = (
    unCollapsedRows,
    refreshData = true,
    spreadSheet,
    notifyChange = true,
    columns
  ) => {
    if (refreshData) {
      dispatch(setRefreshing());
    }
    dispatch(
      updateSpreadsheet({
        payload: {
          value: {
            ...spreadSheet,
            expense: {
              ...spreadSheet?.expense,
              columns,
              unCollapsedRows: unCollapsedRows,
            },
            activeTab: tabValue,
          },
          refreshData,
          entityId,
        },
        spreadSheetId,
        accountingFirmId,
        notifyChange,
      })
    );
  };

  const handleSideNavOpen = () => {
    let id = sideNavOpenId;
    let row = _.cloneDeep(spreadsheetData.value.find((val) => val.id === id));
    row["startDate"] = row?.startDate || durationData.durationStart;
    row["endDate"] = row?.endDate || null;
    row["priorPeriod"] = row?.priorPeriod || null;
    row["priorPeriodValue"] = row?.priorPeriodValue || 0;
    row["priorPeriodIncrementType"] =
      row?.priorPeriodIncrementType || "increase";
    row["priorPeriodType"] = row?.priorPeriodType || "percent";
    row["forecastTimings"] = row?.forecastTimings || [];
    row["accountMetrics"] = row?.accountMetrics || [];
    row["initialValue"] = row?.initialValue || 0;
    setSideNavData(row);
    setSideNavDataInitial(row);
    setSideNavOpen(true);
  };

  const handleAddExpenseItem = (id) => {
    setAddExpenseItemOpen(true);
    setCurrentExpenseItemValue({
      ...defaultExpenseItemValue,
      startDate: durationData.durationStart,
      endDate: null,
      parentId: id,
      id: new ObjectID().toString(),
    });
  };

  const getRows = (value, headerRow, unCollapsedRows) => {
    let index = 0;
    return [
      headerRow,
      ...value
        .slice()
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .filter((val) => {
          return val.rowType === ROW_TYPE.CUSTOM_METRIC ||
            val.rowType === ROW_TYPE.TOTAL_ROW
            ? true
            : unCollapsedRows.includes(val.parentId) &&
                unCollapsedRows.includes(val.subParentId) &&
                (val.rowType === ROW_TYPE.METRIC
                  ? unCollapsedRows.includes(val.metricParentId) &&
                    unCollapsedRows.includes(val.subParentId)
                  : true);
        })
        .map((data, idx) => {
          if (
            data.rowType === ROW_TYPE.CUSTOM_METRIC ||
            data.rowType === ROW_TYPE.TOTAL_ROW ||
            unCollapsedRows.includes(data.parentId)
          ) {
            index++;
            let style = getRowStyle(
              data.rowType,
              data.rowType === ROW_TYPE.METRIC
                ? data.metricType === "Input"
                : data.rowType === ROW_TYPE.ACCOUNT_GROUP
                ? unCollapsedRows.includes(data.id)
                  ? true
                  : false
                : false
            );

            let className = getRowClass(data.rowType, true);
            let rowData = {
              rowId: idx,
              height: 40,
              dataId: value.find((row) => row.id === data.id).id,
              cells: [
                {
                  type: "text",
                  text: `${index}`,
                  nonEditable: true,
                  style: {
                    paddingLeft: "12px",
                    ...style,
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black",
                  },
                },
                {
                  type: "action",
                  active: data.rowType !== ROW_TYPE.ACCOUNT,
                  disabled: !durationAdded,
                  toggleSideNav: () => {
                    setSideNavOpenId(data.id);
                  },
                  style,
                },
                {
                  type: "collapsible",
                  text: data?.metricName,
                  isCustomMetric: data.rowType === ROW_TYPE.CUSTOM_METRIC,
                  hasChildren:
                    value.filter((val) => val?.parentId === data.id).length !==
                      0 ||
                    value.filter((val) => val?.subParentId === data.id)
                      .length !== 0 ||
                    value.filter((val) => val?.metricParentId === data.id)
                      .length !== 0,
                  columnType: "expense",
                  isExpanded: unCollapsedRows.indexOf(data.id) !== -1,
                  totalRow: data.rowType === ROW_TYPE.TOTAL_ROW,
                  disabled: !durationAdded,
                  rowType: data.rowType,
                  metricType: data.metricType,
                  valueType: data.valueType,
                  id: data.id,
                  toggleComment: () => handleOpen(),
                  handleAddItem: (id) => handleAddExpenseItem(id),
                  style,
                  className,
                  parentId: data.parentId,
                  subParentId: data.subParentId,
                  metricParentId: data.metricParentId,
                },
              ],
            };
            if (expenseData.metricSettingsOpen) {
              let metricSetting = [
                {
                  type: "text",
                  text: data?.valueType || "-",
                  nonEditable: true,
                  style,
                  className,
                },
                {
                  type: "text",
                  text: data.metricType,
                  nonEditable: true,
                  style,
                  className,
                },
                {
                  type: "text",
                  text: data.calculationType || "-",
                  nonEditable: true,
                  style,
                  className,
                },
                {
                  type: "text",
                  text: data?.accountMapping?.name || "",
                  nonEditable: true,
                  style,
                  className,
                },
              ];
              metricSetting.forEach((val) => {
                rowData.cells.push(val);
              });
            }
            if (expenseData.priorPeriodOpen) {
              PRIOR_PERRIOD_KEYS.forEach((val) => {
                rowData.cells.push({
                  type: "number",
                  value: data[val] || 0,

                  id: data.id,
                  nonEditable: true,
                  mappable: data.rowType === ROW_TYPE.ACCOUNT,
                  style: { ...style, opacity: 0.5 },
                  className,
                });
              });
            }
            let dateColumns = expenseData?.columns?.reduce((acc, column) => {
              if (!DEFAULT_KEYS.includes(column.columnId)) {
                acc.push(column?.columnId);
              }
              return acc;
            }, []);
            dateColumns.forEach((date) => {
              if (!data[date]?.disabled) {
                rowData.cells.push({
                  type: "number",
                  value: Math.round(data[date]?.value || 0),
                  nonEditable: sideNavDisableList?.includes(data?.id)
                    ? true
                    : data.rowType === ROW_TYPE.ACCOUNT ||
                      data.rowType === ROW_TYPE.METRIC
                    ? false
                    : true,
                  style: {
                    ...style,
                    background: data[date]?.manualInput
                      ? "#B4D4FF"
                      : style.background,
                  },
                  className,
                });
              }
            });
            return rowData;
          } else {
            return null;
          }
        }),
    ].filter((row) => row !== null);
  };

  const handleColumnResize = (ci, width) => {
    const updatedColumns = spreadsheetData.columns.map((column) => {
      if (column.columnId === ci) {
        return { ...column, width };
      }
      return column;
    });
    setSpreadsheetData((prevData) => ({
      ...prevData,
      columns: updatedColumns,
    }));
    handleUpdate(
      spreadsheetData.unCollapsedRows,
      true,
      spreadSheet,
      false,
      updatedColumns
    );
  };

  const handleRemoveManualInput = (rowId, columnId) => {
    let rowData = spreadsheetData.rows.find((val) => val.rowId === rowId);
    let rowIndex = spreadsheetData.value.findIndex(
      (val) => val.id === rowData.dataId
    );
    let clone = _.cloneDeep(spreadsheetData.value);
    clone[rowIndex][columnId]["manualInput"] = false;
    let revaluatedValues = handleValueCalculationsForecastIncluded(
      clone[rowIndex]?.metricType,
      spreadsheetData.value,
      spreadsheetData.columns,
      clone,
      clone[rowIndex],
      "cellchange",
      false,
      null,
      false,
      "rows",
      null,
      durationData
    );
    handleSumRows(revaluatedValues, spreadsheetData.value);
  };

  const handleContextMenu = (
    selectedRowIds,
    selectedColIds,
    selectionMode,
    menuOptions,
    selectedRanges
  ) => {
    if (
      selectionMode === "range" &&
      !DEFAULT_KEYS.includes(selectedRanges?.[0]?.[0]?.columnId)
    ) {
      menuOptions = [
        {
          id: "removeManualInput",
          label: "Remove Manual Input",
          handler: () => {
            let { rowId, columnId } = selectedRanges?.[0]?.[0];
            handleRemoveManualInput(rowId, columnId);
          },
        },
      ];
      return menuOptions;
    }
    return [];
  };

  const handleSumRows = (clone, value, reload = false, notify = true) => {
    let data = handleSumRowsHelper(value, clone);
    let { tabData: referenceSyncedData, spreadsheet } = handleReferenceUpdate(
      spreadSheet,
      data,
      "expense",
      durationData,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity
    );
    setSpreadSheet(spreadsheet);
    setSpreadsheetData({
      ...spreadsheetData,
      rows: getRows(
        referenceSyncedData,
        spreadsheetData.headers,
        spreadsheetData.unCollapsedRows
      ),
      value: data,
    });

    handleUpdate(
      spreadsheetData?.unCollapsedRows,
      reload,
      spreadsheet,
      notify,
      spreadsheetData.columns
    );
  };

  const handleCellChange = (val) => {
    if (val[0].type === "collapsible") {
      let index = spreadsheetData.unCollapsedRows.indexOf(val[0].newCell.id);
      if (index >= 0) {
        let data = _.cloneDeep(spreadsheetData.unCollapsedRows);
        data.splice(index, 1);
        setSpreadsheetData({
          ...spreadsheetData,
          unCollapsedRows: data,
          rows: getRows(spreadsheetData.value, spreadsheetData.headers, data),
        });
        handleUpdate(data, false, spreadSheet, false, spreadsheetData.columns);
      } else {
        let data = [...spreadsheetData.unCollapsedRows, val[0].newCell.id];
        setSpreadsheetData({
          ...spreadsheetData,
          unCollapsedRows: data,
          rows: getRows(spreadsheetData.value, spreadsheetData.headers, data),
        });
        handleUpdate(data, false, spreadSheet, false, spreadsheetData.columns);
      }
    }
    //hanlde manual input
    else {
      let row = spreadsheetData.rows.find((row) => row.rowId === val[0].rowId);
      let clone = _.cloneDeep(spreadsheetData.value);
      let index = spreadsheetData.value
        .map((value) => value.id)
        .indexOf(row.dataId);
      clone[index][val[0].columnId].value = val[0].newCell.value;
      clone[index][val[0].columnId].manualInput = true;
      let revaluatedValues = handleValueCalculationsForecastIncluded(
        clone[index]?.metricType,
        spreadsheetData.value,
        spreadsheetData.columns,
        clone,
        clone[index],
        "cellchange",
        false,
        null,
        false,
        "rows",
        null,
        durationData
      );
      handleSumRows(revaluatedValues, spreadsheetData.value, false, false);
      Mixpanel.track("spreadsheet_expense_manual_input", {
        $distinct_id: new ObjectID().toString(),
        spreadSheetId,
        entityId,
        accountingFirmId,
      });
    }
  };

  const handleCollapsibleMapping = (id) => {
    let index = uncollapsedMapping.indexOf(id);
    if (index >= 0) {
      let clone = _.cloneDeep(uncollapsedMapping);
      clone.splice(index, 1);
      setUnCollapsedMapping(clone);
    } else {
      setUnCollapsedMapping([...uncollapsedMapping, id]);
    }
  };

  const handleMappingSelect = (id) => {
    setCurrentAccountMapping({ ...currentAccountMapping, id });
  };

  const handleChangeCurrentSideNavTab = (e, value) => {
    setCurrentSideNavTab(value);
  };

  const calculateInitialValue = (
    type,
    adjust,
    adjustType,
    adjustCalculation
  ) => {
    let value = 0;
    if (adjustCalculation === "increase") {
      if (adjustType === "percent") {
        value =
          sideNavData[type] + sideNavData[type] * (parseFloat(adjust) / 100);
      } else {
        value = sideNavData[type] + parseInt(adjust);
      }
    } else {
      if (adjustType === "percent") {
        value =
          sideNavData[type] - sideNavData[type] * (parseFloat(adjust) / 100);
      } else {
        value = sideNavData[type] - parseFloat(adjust);
      }
    }

    return value;
  };

  const handleSideNavChanges = (key, value, warningAccepted = false) => {
    let updatedData = { ...sideNavData };
    switch (key) {
      case "metricType":
        if (warningAccepted) {
          let id = new ObjectID().toString();
          updatedData["metricType"] = value;
          updatedData["startDate"] = durationData?.durationStart;
          updatedData["endDate"] = null;
          updatedData["priorPeriod"] = null;
          updatedData["priorPeriodValue"] = 0;
          updatedData["priorPeriodIncrementType"] = "increase";
          updatedData["priorPeriodType"] = "percent";
          updatedData["forecastTimings"] = [];
          updatedData["accountMetrics"] = [id];
          setInitialMetricData({
            id: id,
            metricName: "Expense",
            metricType: "Input",
            valueType: "Currency",
            initialValue: 0,
            forecastTimings: [],
            formulaId: [],
            metricSortOrder: 0,
            parentId: sideNavData.parentId,
            subParentId: sideNavData.subParentId,
            metricParentId: sideNavData.id,
          });

          updatedData["initialValue"] = 0;
          updatedData["initialSave"] = false;
        } else {
          if (sideNavData?.metricType !== value) {
            setModalValues({
              ...defaultModalValues,
              modalType: "metricChange",
              message:
                "Changing this will remove the current logic. Are you sure you want to continue?",
              idValue: value,
            });
            setModalOpen(true);
          }
        }

        break;
      case "metricName":
      case "valueType":
      case "calculationType":
      case "startDate":
      case "endDate":
      case "showInCash":
      case "gstApplicable":
        updatedData[key] = value;
        break;
      case "priorPeriod":
        updatedData["priorPeriod"] = value;
        updatedData["initialValue"] = calculateInitialValue(
          value,
          sideNavData?.priorPeriodValue,
          sideNavData?.priorPeriodType,
          sideNavData?.priorPeriodIncrementType
        );
        break;
      case "priorPeriodValue":
        updatedData["priorPeriodValue"] = value || 0;
        updatedData["initialValue"] = calculateInitialValue(
          sideNavData?.priorPeriod,
          value || 0,
          sideNavData?.priorPeriodType,
          sideNavData?.priorPeriodIncrementType
        );
        break;

      case "priorPeriodIncrementType":
        updatedData["priorPeriodIncrementType"] = value;
        updatedData["initialValue"] = calculateInitialValue(
          sideNavData?.priorPeriod,
          sideNavData?.priorPeriodValue,
          sideNavData?.priorPeriodType,
          value
        );
        break;
      case "priorPeriodType":
        updatedData["priorPeriodType"] = value;
        updatedData["priorPeriodValue"] = 0;
        updatedData["initialValue"] = calculateInitialValue(
          sideNavData?.priorPeriod,
          sideNavData?.priorPeriodValue,
          value,
          sideNavData?.priorPeriodIncrementType
        );
        break;
      default:
        break;
    }
    setSideNavData(updatedData);
  };

  const handleAddForecast = (metricParentId = null) => {
    if (!sideNavData?.initialSave && metricParentId === null)
      handleSideNavSave("open", false, sideNavData?.metricType);
    setForecastModalType("add");
    let start = sideNavData.startDate;
    // if (sideNavData?.forecastTimings?.length !== 0) {
    // }
    setCurrentForecastData({
      ...defaultForecastValues(start, null),
      id: new ObjectID().toString(),
    });
    handleOpenForecast();
  };
  const handleAddAccountMetric = () => {
    setAccountMetricModalType("add");
    let id = new ObjectID().toString();
    setCurrentAccountMetricData({
      ...defaultAccountMetricValues(),
      id,
      parentId: sideNavData?.parentId,
      subParentId: sideNavData?.subParentId,
      metricParentId: sideNavData?.id,
    });
    if (
      !sideNavData.initialSave &&
      !spreadsheetData.value.find(
        (val) => val.metricParentId === sideNavData.id
      )
    ) {
      handleSaveAccountMetric(
        initialMetricData.id,
        {
          ...initialMetricData,
        },
        false
      );
    }
    handleOpenAccountMetric();
  };
  const handleOpenForecast = () => setForecastOpen(true);
  const handleCloseForecast = () => {
    setForecastModalType("add");
    setForecastOpen(false);
    setCurrentForecastData({
      ...defaultForecastValues(),
      id: new ObjectID().toString(),
    });
    setForecastMetricSubmitted(false);
    setCurrentAccountMetricForecastId(null);
    setCurrentAccountMetricData(null);
  };
  const handleOpenAccountMetric = () => setAccountMetricOpen(true);
  const handleCloseAccountMetric = () => {
    setAccountMetricModalType("add");
    setAccountMetricOpen(false);
    setCurrentAccountMetricData({
      ...defaultAccountMetricValues(),
      id: new ObjectID().toString(),
      parentId: sideNavData?.parentId,
      subParentId: sideNavData?.subParentId,
      metricParentId: sideNavData?.id,
    });
    setForecastMetricSubmitted(false);
    setFormulaEdit(false);
    setFormulaIdList([]);
  };

  const forecastValidation = (type) => {
    let forecastType = currentForecastData?.type;
    let condition = false;
    switch (type) {
      case "name":
        condition = currentForecastData?.name !== "";
        break;
      case "type":
        condition = currentForecastData?.type !== null;
        break;
      case "start":
        condition =
          currentForecastData?.start !== null &&
          moment(sideNavData.startDate) <= moment(currentForecastData.start);
        break;
      case "end":
        condition =
          currentForecastData.type === "oneTimeChange" &&
          !currentForecastData.end
            ? true
            : currentForecastData?.end !== null &&
              moment(currentForecastData.start) <=
                moment(currentForecastData.end) &&
              moment(sideNavData.startDate) <= moment(currentForecastData.end);
        break;
      case "changeValue":
        condition = currentForecastData?.changeValue !== null;
        break;
      case "changeType":
        condition =
          forecastType === "growthChange"
            ? currentForecastData?.changeType !== null
            : true;
        break;
      case "changeFormat":
        condition =
          forecastType === "growthChange"
            ? currentForecastData?.changeFormat !== null
            : true;
        break;
      case "changeDuration":
        condition =
          forecastType === "growthChange"
            ? currentForecastData?.changeDuration !== null
            : true;
        break;
      default:
        condition = false;
        break;
    }
    return condition;
  };

  const accountMetricValidation = (type, currentAccountMetricData) => {
    let metricType = currentAccountMetricData?.metricType;
    let condition = false;
    switch (type) {
      case "metricName":
        condition = currentAccountMetricData?.metricName !== "";
        break;
      case "metricType":
        condition = currentAccountMetricData?.metricType !== null;
        break;
      case "valueType":
        condition =
          currentAccountMetricData?.valueType !== null ||
          metricType === "Reference" ||
          metricType === "Calculation";
        break;
      default:
        condition = false;
        break;
    }
    return condition;
  };

  const checkTimingDateOverlap = (forecasts) => {
    let dateRanges = [];
    forecasts.forEach((forecast) => {
      if (!forecast.end) {
        dateRanges.push(forecast.start);
      } else {
        dateRanges.push(...getTimingRange(forecast.start, forecast.end));
      }
    });
    return new Set(dateRanges).size === dateRanges.length;
  };

  const handleSaveForecastConfirm = (id, overrideManual) => {
    let sideNavValue = {
      ...(currentAccountMetricForecastId
        ? spreadsheetData.value.find(
            (val) => val.id === currentAccountMetricForecastId
          )
        : sideNavData),
    };
    let forecasts = [...sideNavValue?.forecastTimings];
    let prevForecast = forecasts.find((forecast) => forecast.id === id);
    if (forecastModalType === "add") {
      forecasts.push(currentForecastData);
    } else {
      forecasts = forecasts.map((forecast) => {
        return forecast.id === id ? currentForecastData : forecast;
      });
    }
    if (checkTimingDateOverlap(forecasts)) {
      let index = spreadsheetData.value
        ?.map((val) => val.id)
        ?.indexOf(sideNavValue?.id);
      let clone = _.cloneDeep(spreadsheetData.value);
      clone[index] = {
        ...sideNavValue,
        forecastTimings: forecasts,
      };
      let revaluedRows = handleValueCalculationsForecastIncluded(
        sideNavValue?.metricType,
        spreadsheetData.value,
        spreadsheetData.columns,
        clone,
        clone[index],
        "saveforecast",
        overrideManual,
        id === "" ? currentForecastData.id : id,
        false,
        "rows",
        prevForecast,
        durationData,
        currentAccountMetricForecastId
          ? getTimingRange(currentForecastData.start, currentForecastData.end)
          : null
      );

      handleSumRows(revaluedRows, spreadsheetData.value, true);
      handleCloseForecast();
      setForecastMetricSubmitted(false);
    } else {
      setModalValues({
        ...modalValues,
        message:
          "There are overlapping dates in the forecast. Please change the dates to be unqiue.",
        singleButton: true,
        confirmText: "Ok",
        modalType: "overlappedDate",
      });
      setModalOpen(true);
    }
  };

  const handleSaveForecast = (id) => {
    setForecastMetricSubmitted(true);
    let sideNavRange = getTimingRange(
      sideNavData.startDate,
      sideNavData.endDate || durationData.durationEnd
    );
    let forecastRange = getTimingRange(
      currentForecastData.start,
      currentForecastData.end || sideNavData.endDate || durationData.durationEnd
    );
    if (getArrayDiff(forecastRange, sideNavRange)?.length !== 0) {
      setModalValues({
        ...modalValues,
        message:
          "The dates of the forecasts are not within the range of the start and end dates.",
        singleButton: true,
        confirmText: "Ok",
        modalType: "dateRangeInvalid",
      });
      setModalOpen(true);
      return;
    }
    let validated =
      forecastValidation("name") &&
      forecastValidation("type") &&
      forecastValidation("start") &&
      forecastValidation("end") &&
      forecastValidation("changeValue") &&
      forecastValidation("changeType") &&
      forecastValidation("changeFormat") &&
      forecastValidation("changeDuration");

    if (validated) {
      checkForecastSave(id);
    }
    if (forecastModalType === "add") {
      Mixpanel.track("forecast_utilization_expenses", {
        $distinct_id: new ObjectID().toString(),
        spreadSheetId,
        entityId,
        accountingFirmId,
      });
    }
  };

  const handleSaveAccountMetric = (
    id,
    metricData = null,
    overrideManual = false
  ) => {
    setForecastMetricSubmitted(true);
    let validated =
      accountMetricValidation(
        "metricName",
        metricData ? metricData : currentAccountMetricData
      ) &&
      accountMetricValidation(
        "metricType",
        metricData ? metricData : currentAccountMetricData
      ) &&
      accountMetricValidation(
        "valueType",
        metricData ? metricData : currentAccountMetricData
      );
    if (validated) {
      let metrics = [...sideNavData?.accountMetrics];
      if (!metrics.includes(id)) {
        metrics.push(metricData ? metricData.id : currentAccountMetricData.id);
      }
      let idMap = spreadsheetData.value?.map((val) => val.id);
      let index = idMap?.indexOf(sideNavData.id);
      let clone = _.cloneDeep(spreadsheetData.value);
      clone[index] = {
        ...sideNavData,
        accountMetrics: metrics,
        initialSave: true,
      };
      let data = {
        ...(metricData ? metricData : currentAccountMetricData),
        parentId: sideNavData?.parentId,
        subParentId: sideNavData?.subParentId,
        metricParentId: sideNavData?.id,
        rowType: ROW_TYPE.METRIC,
        startDate: sideNavData.startDate,
        endDate: sideNavData.endDate,
        metricSortOrder: metricData
          ? metrics?.length - 1
          : currentAccountMetricData.metricSortOrder >= 0
          ? currentAccountMetricData.metricSortOrder
          : metrics?.length - 1,
      };
      if (data.metricType !== "Input") {
        data["forecastTimings"] = [];
      }
      if (data.metricType !== "Calculation") {
        data["formulaId"] = [];
      }
      let metricIndex = idMap.indexOf(
        metricData ? metricData.id : currentAccountMetricData.id
      );
      if (metricIndex === -1) {
        Object.keys(clone[0]).forEach((key) => {
          if (!DEFAULT_KEYS.includes(key)) {
            data[key] = {
              value: 0,
              disabled: clone[0]?.[key]?.["disabled"],
              manualInput: false,
            };
          }
        });
      }
      let dataIndex = index + 1 + (metrics?.length - 1 || 0);
      if (metricIndex !== -1) {
        clone[metricIndex] = data;
      } else {
        clone.splice(dataIndex, 0, data);
      }
      let sortOrderClone = clone.map((val, index) => {
        return { ...val, sortOrder: index + 1 };
      });

      let revaluedRows = handleValueCalculationsForecastIncluded(
        sideNavData?.metricType,
        sortOrderClone,
        spreadsheetData.columns,
        sortOrderClone,
        sortOrderClone[metricIndex]
          ? sortOrderClone[metricIndex]
          : sortOrderClone[dataIndex],
        "saveMetric",
        overrideManual,
        null,
        false,
        "rows",
        null,
        durationData
      );
      handleSumRows(revaluedRows, revaluedRows, true);
      handleCloseAccountMetric();
    }
    if (accountMetricModalType === "add") {
      Mixpanel.track("add_new_metric_expenses", {
        $distinct_id: new ObjectID().toString(),
        spreadSheetId,
        entityId,
        accountingFirmId,
      });
    }
    if (currentAccountMetricData?.metricType === "Input") {
      Mixpanel.track("input_metric_type_expenses", {
        $distinct_id: new ObjectID().toString(),
        spreadSheetId,
        entityId,
        accountingFirmId,
      });
    } else if (currentAccountMetricData?.metricType === "Calculation") {
      Mixpanel.track("calculation_metric_type_expenses", {
        $distinct_id: new ObjectID().toString(),
        spreadSheetId,
        entityId,
        accountingFirmId,
      });
    } else if (currentAccountMetricData?.metricType === "Reference") {
      Mixpanel.track("reference_metric_type_expenses", {
        $distinct_id: new ObjectID().toString(),
        spreadSheetId,
        entityId,
        accountingFirmId,
      });
    }
  };

  const handleEditForecast = (id, data = null) => {
    let sideNavValue = data ? data : sideNavData;
    let forecast = sideNavValue?.forecastTimings?.find(
      (forecast) => forecast.id === id
    );
    setForecastModalType("edit");
    setCurrentForecastData(forecast);
    setForecastOpen(true);
  };

  const handleEditAccountMetric = (id, initial = false) => {
    let metric = initial
      ? initialMetricData
      : spreadsheetData.value?.find((val) => val.id === id);
    setAccountMetricModalType("edit");
    setCurrentAccountMetricData(metric);
    setAccountMetricOpen(true);
  };

  const handleDeleteForecast = (id) => {
    let sideNavValue = currentAccountMetricForecastId
      ? spreadsheetData.value.find(
          (val) => val.id === currentAccountMetricForecastId
        )
      : sideNavData;
    let forecasts = sideNavValue?.forecastTimings?.filter(
      (forecast) => forecast.id !== id
    );
    let index = spreadsheetData.value
      ?.map((val) => val.id)
      ?.indexOf(sideNavValue.id);
    let clone = _.cloneDeep(spreadsheetData.value);
    clone[index] = {
      ...clone[index],
      forecastTimings: forecasts,
    };
    let revaluedRows = handleValueCalculationsForecastIncluded(
      sideNavValue?.metricType,
      spreadsheetData.value,
      spreadsheetData.columns,
      clone,
      clone[index],
      "deleteforecast",
      false,
      null,
      false,
      "rows",
      null,
      durationData
    );
    handleSumRows(revaluedRows, spreadsheetData.value, true);
  };

  const handleDeleteAccountMetric = (id) => {
    let metrics = sideNavData?.accountMetrics?.filter(
      (metric) => metric !== id
    );

    let index = spreadsheetData.value
      ?.map((val) => val.id)
      ?.indexOf(sideNavData.id);
    let clone = _.cloneDeep(spreadsheetData.value);
    clone[index] = {
      ...clone[index],
      accountMetrics: metrics,
    };

    let filteredClone = clone.filter((val) => val.id !== id);
    let metricRowsFiltered = filteredClone.map((val) => {
      if (val.metricType === "Calculation") {
        let formulaIndex = val?.formulaId?.indexOf(id);
        let value = { ...val };
        if (formulaIndex >= 0) {
          let formulaId = value.formulaId;
          formulaId.splice(formulaIndex, 1);
          value = {
            ...value,
            formulaId,
          };
        }
        return value;
      } else {
        return val;
      }
    });

    let revaluedRows = handleValueCalculationsForecastIncluded(
      sideNavData?.metricType,
      metricRowsFiltered,
      spreadsheetData.columns,
      metricRowsFiltered,
      { ...sideNavData, forecastTimings: [] },
      "saved",
      false,
      null,
      false,
      "rows",
      null,
      durationData
    );
    revaluedRows[index] = {
      ...revaluedRows[index],
      accountMetrics: metrics,
    };
    handleSumRows(revaluedRows, revaluedRows, true);
  };

  const handleChangeForecast = (type, value) => {
    let updatedData = { ...currentForecastData };
    switch (type) {
      case "name":
        updatedData["name"] = value;
        break;
      case "type":
        updatedData["type"] = value;
        updatedData["changeValue"] = 0;
        if (value !== "oneTimeChange") {
          updatedData["end"] = updatedData["start"];
        }
        break;
      case "date":
        updatedData["start"] = value[0];
        updatedData["end"] = value[1];
        break;
      case "start":
        updatedData["start"] = value;
        break;
      case "end":
        updatedData["end"] = value;
        break;
      case "changeType":
        updatedData["changeType"] = value;
        break;
      case "changeValue":
        updatedData["changeValue"] = value;
        break;
      case "changeFormat":
        updatedData["changeFormat"] = value;
        break;
      case "changeDuration":
        updatedData["changeDuration"] = value;
        break;
      case "startEnd":
        updatedData["start"] = value;
        updatedData["end"] = value;
        break;
      default:
        break;
    }
    setCurrentForecastData(updatedData);
  };

  const handleChangeAccountMetric = (type, value) => {
    let updatedData = { ...currentAccountMetricData };
    switch (type) {
      case "metricName":
        updatedData["metricName"] = value;
        break;
      case "metricType":
        updatedData["metricType"] = value;
        updatedData["initialValue"] = 0;
        updatedData["valueType"] = null;
        if (value === "Input") {
          updatedData["valueType"] = "Currency";
          updatedData["calculationType"] = null;
        }
        if (value === "Calculation") {
          updatedData["valueType"] = null;
          updatedData["calculationType"] = "Add";
        }
        if (value !== "Reference") {
          updatedData["referenceTab"] = null;
          updatedData["referenceId"] = null;
        }
        break;
      case "referenceTab":
        updatedData["referenceTab"] = value;
        break;
      case "referenceId":
        updatedData["referenceId"] = value;
        break;
      case "valueType":
        updatedData["valueType"] = value;
        updatedData["initialValue"] = 0;
        break;
      case "calculationType":
        updatedData["calculationType"] = value;
        break;
      case "formulaId":
        updatedData["formulaId"] = value;
        break;
      case "initialValue":
        updatedData["initialValue"] = value;
        break;
      default:
        break;
    }
    setCurrentAccountMetricData(updatedData);
  };

  const handleCloseSideNav = () => {
    setSideNavData(null);
    setSideNavOpenId(null);
    setSideNavOpen(false);
    setCurrentAccountMetricData(null);
    setCurrentAccountMetricForecastId(null);
    setAllowSortOrder(false);
  };

  const sideNavValidation = () => {
    if (!sideNavData.endDate) {
      return true;
    } else {
      if (moment(sideNavData.startDate) <= moment(sideNavData.endDate)) {
        return true;
      }
    }
    return false;
  };

  const handleSideNavSave = (type, overrideManual, metricType = "Xero") => {
    if (currentSideNavTab === 0 || currentSideNavTab === 1) {
      let validated = sideNavValidation();

      if (validated) {
        if (metricType === "Xero") {
          let rows = spreadsheetData.value.filter((val) => {
            return val?.metricParentId !== sideNavData?.id;
          });
          let revaluedRows = handleValueCalculationsForecastIncluded(
            sideNavData?.metricType,
            rows,
            spreadsheetData.columns,
            rows,
            { ...sideNavData, accountMetrics: [] },
            "saved",
            overrideManual,
            null,
            false,
            "rows",
            null,
            durationData
          );
          handleSumRows(revaluedRows, rows, true);
        } else {
          let valueClone = _.cloneDeep(spreadsheetData.value);
          let idMap = spreadsheetData.value.map((val) => val.id);
          let index = spreadsheetData.value
            ?.map((val) => val.id)
            ?.indexOf(sideNavData.id);
          valueClone[index] = {
            ...sideNavData,
            initialSave: true,
          };
          let clone = valueClone.map((val) => {
            if (val?.metricParentId && val?.metricParentId === sideNavData.id) {
              return {
                ...val,
                startDate: sideNavData.startDate,
                endDate: sideNavData.endDate,
              };
            } else {
              return val;
            }
          });
          sideNavData?.accountMetrics?.forEach((metric) => {
            if (!idMap.includes(metric) || !sideNavData?.initialSave) {
              let data = {
                ...initialMetricData,
                parentId: sideNavData?.parentId,
                subParentId: sideNavData?.subParentId,
                metricParentId: sideNavData.id,
                rowType: ROW_TYPE.METRIC,
              };
              Object.keys(clone[0]).forEach((key) => {
                if (!DEFAULT_KEYS.includes(key)) {
                  data[key] = {
                    value: 0,
                    disabled: clone[0]?.[key]?.["disabled"],
                    manualInput: false,
                  };
                }
              });
              clone.splice(index + 1, 0, data);
            }
          });

          let positionChangeData = cloneDeep(clone)?.filter((row) => {
            if (row?.metricParentId !== sideNavData?.id) {
              return true;
            } else {
              if (row?.metricSortOrder === 0) {
                return true;
              }
            }
            return false;
          });
          let sideNavAccountMetrics = [];
          sideNavData?.accountMetrics?.forEach((row) => {
            let data = clone.find((val) => val.id === row);
            if (data?.metricSortOrder !== 0) {
              sideNavAccountMetrics.push(data);
            }
          });

          positionChangeData.splice(index + 2, 0, ...sideNavAccountMetrics);
          positionChangeData = positionChangeData.map((val, index) => {
            return { ...val, sortOrder: index + 1 };
          });
          let sortOrderClone = positionChangeData.map((val, index) => {
            return { ...val, sortOrder: index + 1 };
          });
          let revaluedRows = handleValueCalculationsForecastIncluded(
            sideNavData?.metricType,
            sortOrderClone,
            spreadsheetData.columns,
            sortOrderClone,
            { ...{ sideNavData, initialSave: true }, forecastTimings: [] },
            "saved",
            overrideManual,
            null,
            false,
            "rows",
            null,
            durationData
          );
          let revaluatedValuesClone = cloneDeep(revaluedRows);
          if (
            sideNavData.metricType === "Calculation" &&
            !sideNavData.metricParentId
          ) {
            revaluedRows.forEach((row, index) => {
              if (row.metricParentId === sideNavData.id) {
                revaluatedValuesClone = handleValueCalculationsForecastIncluded(
                  row?.metricType,
                  revaluatedValuesClone,
                  spreadsheetData.columns,
                  revaluatedValuesClone,
                  {
                    ...row,
                    startDate: sideNavData.startDate,
                    endDate: sideNavData.endDate,
                  },
                  "saved",
                  overrideManual,
                  null,
                  false,
                  "rows",
                  null,
                  durationData
                );
              }
            });
          }
          handleSumRows(revaluatedValuesClone, revaluatedValuesClone, true);
        }
        if (
          sideNavData.metricType !== sideNavDataInitial.metricType &&
          (sideNavDataInitial.metricType === "Xero" ||
            sideNavDataInitial.metricType === "Calculation") &&
          sideNavDataInitial.rowType === ROW_TYPE.ACCOUNT
        ) {
          if (sideNavData.metricType === "Xero") {
            //metric_type_calculation_to_xero
            Mixpanel.track("metric_type_calculation_to_xero_expenses", {
              $distinct_id: new ObjectID().toString(),
              spreadSheetId,
              entityId,
              accountingFirmId,
            });
          } else {
            Mixpanel.track("metric_type_xero_to_calculation_expenses", {
              distinct_id: new ObjectID().toString(),
              spreadSheetId,
              entityId,
              accountingFirmId,
            });
          }
        }
        if (!sideNavData.initialSave) {
          setCurrentSideNavTab(1);
        } else if (type === "close") {
          handleCloseSideNav();
          setCurrentSideNavTab(0);
        }
      }
    }
  };

  const clearMetric = (accountMetric = false) => {
    let accountMetrics = sideNavData?.accountMetrics || [];
    let firstMetricSliced = accountMetrics.slice(1);
    let filteredValue = accountMetric
      ? spreadsheetData.value
          .filter((val) => !firstMetricSliced?.includes(val.id))
          .map((data) => {
            let val = _.cloneDeep(data);
            if (accountMetrics.includes(val.id)) {
              Object.keys(val).forEach((key) => {
                if (!DEFAULT_KEYS.includes(key)) {
                  val[key] = {
                    disabled: val[key]["disabled"],
                    value: 0,
                    manualInput: false,
                  };
                }
              });
              val["metricType"] = "Input";
              val["valueType"] = "Currency";
              val["formulaId"] = [];

              return val;
            }
            return val;
          })
      : spreadsheetData.value;
    let revaluedRows = handleValueCalculationsForecastIncluded(
      sideNavData?.metricType,
      accountMetric ? filteredValue : spreadsheetData.value,
      spreadsheetData.columns,
      accountMetric ? filteredValue : spreadsheetData.value,
      {
        ...sideNavData,
        priorPeriod: "",
        priorPeriodValue: 0,
        priorPeriodIncrementType: "",
        priorPeriodType: "",
        initialValue: 0,
        forecastTimings: [],
        cashTiming: [100],
        accountMetrics: accountMetric ? [sideNavData?.accountMetrics[0]] : [],
        startDate: durationData?.durationStart,
        endDate: null,
        initialSave: false,
      },
      "reset",
      false,
      null,
      true,
      "rows",
      null,
      durationData
    );
    setForecastMetricSubmitted(false);
    handleSumRows(
      revaluedRows,
      accountMetric ? revaluedRows : spreadsheetData.value,
      true
    );
    handleCloseSideNav();
    setCurrentSideNavTab(0);
  };

  const handleClearMetric = () => {
    setModalValues({
      ...modalValues,
      message:
        "Are you sure you want to clear the metric? This is not reverisble!",
      singleButton: false,
      modalType: "clearMetric",
    });
    setModalOpen(true);
  };
  const handleChangeMetricPosition = (items) => {
    let metricIdList = [sideNavData?.accountMetrics?.[0]];
    items.forEach((item) => {
      metricIdList.push(item.id);
    });
    setSortItems(
      metricIdList?.reduce((acc, val) => {
        let data = spreadsheetData.value?.find((row) => row.id === val);
        if (data.metricSortOrder !== 0) {
          acc.push(data);
        }
        return acc;
      }, [])
    );
  };

  const handleSaveSortedItems = () => {
    setSideNavData({
      ...sideNavData,
      accountMetrics: [
        sideNavData?.accountMetrics?.[0],
        ...sortItems.map((item) => item.id),
      ],
    });

    setSortItems([]);
    setAllowSortOrder(false);
  };

  const handleChangeMetricsExpanded = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : null);
  };

  const getMetricSettingContent = () => {
    return (
      <Grid item xs={12} className="p-10">
        <Grid container>
          <Grid item xs={12}>
            {sideNavData?.gstApplicable !== undefined && (
              <Grid container justifyContent={"flex-end"}>
                <Typography>GST Applicable</Typography>
                <AntSwitch
                  disabled={sideNavDisableList?.includes(sideNavData?.id)}
                  checked={
                    sideNavDisableList?.includes(sideNavData?.id)
                      ? true
                      : sideNavData?.gstApplicable
                      ? false
                      : true
                  }
                  onChange={() =>
                    handleSideNavChanges(
                      "gstApplicable",
                      !sideNavData?.gstApplicable
                    )
                  }
                />
                <Typography>Not Applicable</Typography>
              </Grid>
            )}
          </Grid>
          {!sideNavData?.xeroSynced && (
            <Grid item xs={12}>
              <InputLabel className="font-label mb-10">Metric Name</InputLabel>
              <TextField
                value={sideNavData?.metricName}
                fullWidth
                size="small"
                onChange={({ target }) => {
                  handleSideNavChanges("metricName", target.value);
                }}
              ></TextField>
            </Grid>
          )}
          <Grid item xs={12} className="mt-10">
            <InputLabel className="font-label mb-10">Metric Type</InputLabel>
            <Select
              value={sideNavData?.metricType}
              fullWidth
              size="small"
              disabled={
                !!(
                  sideNavData?.manualAccountMetric && !sideNavData?.xeroSynced
                ) || sideNavDisableList?.includes(sideNavData?.id)
              }
              onChange={({ target }) => {
                handleSideNavChanges("metricType", target.value);
              }}
            >
              {METRIC_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {false && (
            <Grid item xs={12}>
              <InputLabel className="font-label mb-10">Value Type</InputLabel>
              <Select
                value={sideNavData?.valueType}
                fullWidth
                size="small"
                onChange={({ target }) => {
                  handleSideNavChanges("valueType", target.value);
                }}
              >
                {VALUE_TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {false && (
            <Grid item xs={12} className="mt-10">
              <InputLabel className="font-label mb-10">
                Calculation Type
              </InputLabel>
              <Select
                value={sideNavData?.calculationType}
                fullWidth
                size="small"
                onChange={({ target }) => {
                  handleSideNavChanges("calculationType", target.value);
                }}
              >
                {CALCULATION_TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={12} className="mt-20">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <InputLabel className="font-label mb-10">Start Date</InputLabel>
                <DatePicker
                  format={"MMMM YYYY"}
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={sideNavDisableList?.includes(sideNavData?.id)}
                  minDate={dayjs(durationData?.durationStart)}
                  maxDate={dayjs(durationData?.durationEnd)}
                  allowClear={false}
                  size="large"
                  value={dayjs(sideNavData?.startDate)}
                  picker="month"
                  onChange={(date) => {
                    handleSideNavChanges(
                      "startDate",
                      date.year() + "-" + (date.month() + 1)
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className="font-label mb-10">
                  End Date (Optional)
                </InputLabel>
                <DatePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={sideNavDisableList?.includes(sideNavData?.id)}
                  format={"MMMM YYYY"}
                  style={{ width: "100%" }}
                  minDate={dayjs(sideNavData?.startDate)}
                  maxDate={dayjs(durationData?.durationEnd)}
                  size="large"
                  value={
                    sideNavData?.endDate ? dayjs(sideNavData?.endDate) : null
                  }
                  status={
                    dayjs(sideNavData?.startDate) > dayjs(sideNavData?.endDate)
                      ? "error"
                      : ""
                  }
                  picker="month"
                  onChange={(date) => {
                    handleSideNavChanges(
                      "endDate",
                      date.year() + "-" + (date.month() + 1)
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {sideNavData?.metricType === "Xero" && (
            <Grid container>
              <Grid item xs={12} className="mt-25">
                <Typography variant="body1">
                  Use prior period as forecast
                </Typography>
              </Grid>
              <Grid item xs={12} className="mt-10">
                <InputLabel className="font-label mb-10">
                  <span> Select prior period </span>
                  {sideNavData?.priorPeriod === "sameMonthPriorYearData" && (
                    <Tooltip
                      title={
                        <div>
                          <p className="f-16">Prior Values</p>
                          {[...sideNavData?.sameMonthPriorYearData]
                            ?.sort((a, b) => a?.month - b?.month)
                            ?.sort((a, b) => a?.year - b?.year)
                            ?.map((val) => {
                              return (
                                <p className="f-16">
                                  {MONTH_MAP[val?.month]?.toUpperCase()}-
                                  {val?.year} : {val?.amount}
                                </p>
                              );
                            })}
                        </div>
                      }
                    >
                      <IconButton className="p-0 ml-10">
                        <Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputLabel>

                <TextField
                  select
                  size="small"
                  value={sideNavData?.priorPeriod}
                  fullWidth
                  disabled={sideNavDisableList?.includes(sideNavData?.id)}
                >
                  {MAPPING_TYPES.map((mapping) => {
                    return (
                      <MenuItem
                        key={mapping.label}
                        value={mapping.value}
                        onClick={() => {
                          handleSideNavChanges("priorPeriod", mapping.value);
                        }}
                      >
                        {mapping.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} className="mt-20">
                <InputLabel className="font-label mb-10">
                  Adjust by :
                </InputLabel>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      size="small"
                      value={parseFloat(
                        sideNavData?.priorPeriodValue
                      ).toString()}
                      type="number"
                      disabled={sideNavDisableList?.includes(sideNavData?.id)}
                      inputProps={{ min: 0, step: 0.1 }}
                      onKeyDown={(event) => {
                        if (
                          event?.key === "e" ||
                          event?.key === "+" ||
                          event?.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={({ target }) => {
                        if (
                          parseFloat(target.value || 0) <
                            (sideNavData?.priorPeriodType === "percent"
                              ? 100000
                              : 1000000000) &&
                          target.value.length < 10
                        ) {
                          handleSideNavChanges(
                            "priorPeriodValue",
                            parseFloat(target.value)
                          );
                        }
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      disabled={sideNavDisableList?.includes(sideNavData?.id)}
                      value={sideNavData?.priorPeriodIncrementType}
                    >
                      {[
                        { value: "increase", label: "Increase" },
                        { value: "decrease", label: "Decrease" },
                      ].map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => {
                            handleSideNavChanges(
                              "priorPeriodIncrementType",
                              option.value
                            );
                          }}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} className="mt-10">
                    <Grid container>
                      <Typography>By Percent</Typography>
                      <AntSwitch
                        disabled={sideNavDisableList?.includes(sideNavData?.id)}
                        checked={
                          sideNavData?.priorPeriodType === "percent"
                            ? false
                            : true
                        }
                        onChange={() =>
                          handleSideNavChanges(
                            "priorPeriodType",
                            sideNavData?.priorPeriodType === "percent"
                              ? "amount"
                              : "percent"
                          )
                        }
                      />
                      <Typography>By Amount</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {sideNavData?.priorPeriod !== "sameMonthPriorYearData" && (
                <Grid item xs={12} className="mt-20">
                  <p>Initial Value: {sideNavData?.initialValue || 0} </p>
                </Grid>
              )}
            </Grid>
          )}

          {sideNavData?.metricType !== "Xero" && (
            <Grid item xs={12} className="mt-20">
              <Grid
                container
                display={"flex"}
                alignItems={"center"}
                mt={2}
                mb={1}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Account Metrics</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent={"flex-end"}>
                    <IconButton
                      className="mr-10"
                      disabled={
                        allowSortOrder ||
                        sideNavData?.accountMetrics.length <= 1 ||
                        sideNavDisableList?.includes(sideNavData?.id)
                      }
                      onClick={() => {
                        setSortItems(
                          sideNavData?.accountMetrics?.reduce((acc, val) => {
                            let data = spreadsheetData.value.find(
                              (row) => row.id === val
                            );
                            if (data.metricSortOrder !== 0) {
                              acc.push(data);
                            }
                            return acc;
                          }, [])
                        );

                        setAllowSortOrder(true);
                      }}
                    >
                      <SwapVert />
                    </IconButton>
                    <Button
                      disabled={
                        sideNavData?.metricType === "Xero" ||
                        sideNavDisableList?.includes(sideNavData?.id)
                      }
                      endIcon={<Add />}
                      size="small"
                      variant="outlined"
                      onClick={handleAddAccountMetric}
                    >
                      Add metric
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {sideNavData?.accountMetrics?.length !== 0 &&
                (sideNavData?.initialSave ||
                  spreadsheetData?.value?.filter(
                    (val) => val?.metricParentId === sideNavData?.id
                  ).length !== 0) && (
                  <Grid
                    container
                    style={{ maxHeight: "500px", overflow: "auto" }}
                  >
                    {sideNavData?.accountMetrics
                      ?.filter((id) => {
                        let data = spreadsheetData.value.find(
                          (val) => val.id === id
                        );
                        if (data?.metricSortOrder === 0) {
                          return true;
                        }
                        if (data?.metricSortOrder !== 0 && !allowSortOrder) {
                          return true;
                        }
                        return false;
                      })
                      .map((metric, index) => {
                        let metricDetail = spreadsheetData.value.find(
                          (val) => val.id === metric
                        );
                        return (
                          <Grid item xs={12} className="mt-10">
                            <Accordion
                              sx={{ mb: 2, borderRadius: 2 }}
                              expanded={metricDetail?.id === expanded}
                              onChange={handleChangeMetricsExpanded(
                                metricDetail?.id
                              )}
                              disableGutters={true}
                            >
                              <AccordionSummary
                                expandIcon={
                                  metricDetail?.forecastTimings?.length !==
                                  0 ? (
                                    <ExpandMore htmlColor="black" />
                                  ) : (
                                    <></>
                                  )
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{
                                  fontSize: "18px",
                                  backgroundColor:
                                    metricDetail?.forecastTimings?.length !== 0
                                      ? "#B3D4FF"
                                      : "#f0f0f0",
                                  borderRadius: 2,
                                }}
                              >
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={8} display={"flex"}>
                                    <Typography className="mt-5">
                                      {metricDetail?.metricName} (
                                      {metricDetail?.metricType})
                                    </Typography>
                                    {metricDetail?.metricType ===
                                      "Calculation" && (
                                      <Tooltip
                                        title={
                                          <div>
                                            <Typography>
                                              Calculation Type:{" "}
                                              {metricDetail?.calculationType}
                                            </Typography>
                                            <p>
                                              {metricDetail.formulaId.map(
                                                (id) => {
                                                  let detail =
                                                    spreadsheetData.value.find(
                                                      (val) => val.id === id
                                                    );
                                                  return (
                                                    <Chip
                                                      className="mr-10 mt-5"
                                                      label={detail?.metricName}
                                                      color="info"
                                                    />
                                                  );
                                                }
                                              )}
                                            </p>
                                          </div>
                                        }
                                        className="mt-5 ml-10"
                                      >
                                        <Info />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Grid container justifyContent={"flex-end"}>
                                      {metricDetail?.metricType === "Input" && (
                                        <IconButton
                                          disabled={
                                            allowSortOrder ||
                                            sideNavDisableList?.includes(
                                              sideNavData?.id
                                            )
                                          }
                                          size="small"
                                          onClick={() => {
                                            setCurrentAccountMetricForecastId(
                                              metricDetail.id
                                            );
                                            setCurrentAccountMetricData(
                                              metricDetail
                                            );
                                            handleAddForecast(
                                              metricDetail.metricParentId
                                            );
                                          }}
                                        >
                                          <AccessTime fontSize="small" />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        disabled={
                                          allowSortOrder ||
                                          sideNavDisableList?.includes(
                                            sideNavData?.id
                                          )
                                        }
                                        onClick={() => {
                                          handleEditAccountMetric(metric);
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                      {index !== 0 && (
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            setModalValues({
                                              ...modalValues,
                                              message: ` Are you sure you want to delete the metric? The metric if associated to other calculation will be removed from calculation as well.
                     This is not reverisble!`,
                                              modalType: "deleteMetric",
                                              idValue: metric,
                                            });
                                            setModalOpen(true);
                                          }}
                                          disabled={
                                            allowSortOrder ||
                                            sideNavDisableList?.includes(
                                              sideNavData?.id
                                            )
                                          }
                                        >
                                          <Delete
                                            color={
                                              allowSortOrder ||
                                              sideNavDisableList?.includes(
                                                sideNavData?.id
                                              )
                                                ? "disabled"
                                                : "error"
                                            }
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container>
                                    {metricDetail?.forecastTimings?.length !==
                                      0 && (
                                      <TableContainer
                                        style={{ maxHeight: 200 }}
                                      >
                                        <Table stickyHeader>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell width={"70%"}>
                                                Timings
                                              </TableCell>
                                              <TableCell>Actions</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {metricDetail?.forecastTimings?.map(
                                              (forecast) => {
                                                return (
                                                  <TableRow key={forecast?.id}>
                                                    <TableCell>
                                                      {getForecastName(
                                                        forecast
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      <IconButton
                                                        disabled={sideNavDisableList?.includes(
                                                          sideNavData?.id
                                                        )}
                                                        onClick={() => {
                                                          setCurrentAccountMetricData(
                                                            metricDetail
                                                          );
                                                          setCurrentAccountMetricForecastId(
                                                            metricDetail.id
                                                          );
                                                          handleEditForecast(
                                                            forecast?.id,
                                                            metricDetail
                                                          );
                                                        }}
                                                      >
                                                        <Edit />
                                                      </IconButton>
                                                      <IconButton
                                                        disabled={sideNavDisableList?.includes(
                                                          sideNavData?.id
                                                        )}
                                                        onClick={() => {
                                                          setCurrentAccountMetricForecastId(
                                                            metricDetail?.id
                                                          );
                                                          setModalValues({
                                                            ...modalValues,
                                                            message: ` Are you sure you want to delete forecast
                                     This is not reverisble!`,
                                                            modalType:
                                                              "deleteForecast",
                                                            idValue:
                                                              forecast?.id,
                                                          });
                                                          setModalOpen(true);
                                                        }}
                                                        color="error"
                                                      >
                                                        <Delete />
                                                      </IconButton>
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              }
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Accordion>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              {sideNavData?.accountMetrics?.length !== 0 &&
                !(
                  sideNavData?.initialSave ||
                  spreadsheetData?.value?.filter(
                    (val) => val?.metricParentId === sideNavData?.id
                  ).length !== 0
                ) && (
                  <Grid container>
                    <Grid
                      className="account-metric-div mt-10"
                      item
                      xs={12}
                      key={initialMetricData?.id}
                      onClick={() => {
                        handleEditAccountMetric(initialMetricData?.id, true);
                      }}
                    >
                      <Grid container>
                        <Grid item xs={8}>
                          <Typography>
                            {initialMetricData?.metricName} (
                            {initialMetricData?.metricType})
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              <div
                style={{
                  border: allowSortOrder ? "1px solid black" : "",
                  borderRadius: allowSortOrder ? "10px" : "",
                  padding: allowSortOrder ? "10px" : "",
                  marginTop: allowSortOrder ? "20px" : "10",
                }}
              >
                <div style={{ maxHeight: "500px", overflow: "auto" }}>
                  <SortableList
                    items={sortItems}
                    onChange={handleChangeMetricPosition}
                    renderItem={(metric) => {
                      return (
                        <SortableList.Item id={metric.id}>
                          <Grid
                            className="account-metric-div mt-10"
                            item
                            xs={12}
                            key={metric}
                          >
                            <Grid container>
                              <Grid item xs={allowSortOrder ? 7 : 8}>
                                <Typography className="mt-5">
                                  {metric?.metricName} ({metric?.metricType})
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Grid container justifyContent={"flex-end"}>
                                  {metric?.metricType === "Input" && (
                                    <IconButton
                                      disabled={allowSortOrder}
                                      size="small"
                                      onClick={() => {
                                        setCurrentAccountMetricForecastId(
                                          metric.id
                                        );
                                        setCurrentAccountMetricData(metric);
                                        handleAddForecast(
                                          metric.metricParentId
                                        );
                                      }}
                                    >
                                      <AccessTime fontSize="small" />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    onClick={() => {
                                      handleEditAccountMetric(metric.id);
                                    }}
                                    disabled={allowSortOrder}
                                  >
                                    <Edit />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    disabled={allowSortOrder}
                                    onClick={() => {
                                      setModalValues({
                                        ...modalValues,
                                        message: ` Are you sure you want to delete the metric? The metric if associated to other calculation will be removed from calculation as well.
                       This is not reverisble!`,
                                        modalType: "deleteMetric",
                                        idValue: metric.id,
                                      });
                                      setModalOpen(true);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              {allowSortOrder && (
                                <Grid item xs={1}>
                                  <SortableList.DragHandle />
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Grid container>
                                  {metric?.forecastTimings?.length !== 0 && (
                                    <TableContainer style={{ maxHeight: 200 }}>
                                      <Table stickyHeader>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell width={"70%"}>
                                              Timings
                                            </TableCell>
                                            <TableCell>Actions</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {metric?.forecastTimings?.map(
                                            (forecast) => {
                                              return (
                                                <TableRow key={forecast?.id}>
                                                  <TableCell>
                                                    {getForecastName(forecast)}
                                                  </TableCell>
                                                  <TableCell>
                                                    <IconButton
                                                      onClick={() => {
                                                        setCurrentAccountMetricData(
                                                          metric
                                                        );
                                                        setCurrentAccountMetricForecastId(
                                                          metric.id
                                                        );
                                                        handleEditForecast(
                                                          forecast?.id,
                                                          metric
                                                        );
                                                      }}
                                                    >
                                                      <Edit />
                                                    </IconButton>
                                                    <IconButton
                                                      onClick={() => {
                                                        setCurrentAccountMetricForecastId(
                                                          metric?.id
                                                        );
                                                        setModalValues({
                                                          ...modalValues,
                                                          message: ` Are you sure you want to delete forecast
                                       This is not reverisble!`,
                                                          modalType:
                                                            "deleteForecast",
                                                          idValue: forecast?.id,
                                                        });
                                                        setModalOpen(true);
                                                      }}
                                                      color="error"
                                                    >
                                                      <Delete />
                                                    </IconButton>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            }
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </SortableList.Item>
                      );
                    }}
                  />
                </div>

                {allowSortOrder && (
                  <Grid container className="mt-10" justifyContent={"flex-end"}>
                    <Button
                      variant="outlined"
                      className="mr-10"
                      onClick={() => {
                        setSortItems([]);
                        setAllowSortOrder(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleSaveSortedItems} variant="contained">
                      Save
                    </Button>
                  </Grid>
                )}
              </div>
            </Grid>
          )}

          {sideNavData?.metricType === "Xero" && (
            <Grid container>
              <Grid item xs={12} className="mt-20">
                <Button
                  disabled={
                    !sideNavData?.priorPeriod ||
                    sideNavDisableList?.includes(sideNavData?.id)
                  }
                  onClick={() => handleAddForecast()}
                  variant="outlined"
                >
                  Add Forecast Timing
                </Button>
              </Grid>
              {sideNavData?.forecastTimings?.length !== 0 && (
                <Grid item xs={12} className="mt-20">
                  <Grid container>
                    <TableContainer style={{ maxHeight: 400 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell width={"80%"}>Timings</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sideNavData?.forecastTimings?.map((forecast) => {
                            return (
                              <TableRow key={forecast?.id}>
                                <TableCell>
                                  {getForecastName(forecast)}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() =>
                                      handleEditForecast(forecast?.id)
                                    }
                                    disabled={sideNavDisableList?.includes(
                                      sideNavData?.id
                                    )}
                                  >
                                    <Edit />
                                  </IconButton>
                                  <IconButton
                                    disabled={sideNavDisableList?.includes(
                                      sideNavData?.id
                                    )}
                                    onClick={() => {
                                      setModalValues({
                                        ...modalValues,
                                        message: ` Are you sure you want to delete ${
                                          sideNavData?.metricType === "Xero"
                                            ? "forecast"
                                            : "metric"
                                        } ? This is not reverisble!`,
                                        modalType: "deleteForecast",
                                        idValue: forecast?.id,
                                      });
                                      setModalOpen(true);
                                    }}
                                    color="error"
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const handleAddPaymentTiming = () => {
    let updatedSideNavData = _.cloneDeep(sideNavData);
    if (updatedSideNavData?.cashTiming?.length > 12) {
      setModalValues({
        ...modalValues,
        message: "Only Month + 12 timings can be added",
        modalType: "exceedCashTiming",
        singleButton: true,
      });
      setModalOpen(true);
      return;
    }
    updatedSideNavData.cashTiming.push(0);
    setSideNavData(updatedSideNavData);
  };
  const handlePaymentTimingChange = (index, value) => {
    let updatedSideNavData = _.cloneDeep(sideNavData);
    updatedSideNavData.cashTiming[index] = value;
    setSideNavData(updatedSideNavData);
  };

  const handleDeleteCashTiming = (index) => {
    let updatedSideNavData = _.cloneDeep(sideNavData);
    let timings = sideNavData.cashTiming;
    timings.splice(index, 1);
    updatedSideNavData.cashTiming = timings;
    setSideNavData(updatedSideNavData);
  };

  const getCashTimingContent = () => {
    return (
      <Grid item xs={12} className="p-10 mt-10 ">
        <Grid container>
          <Grid item xs={12}>
            {sideNavData?.showInCash !== undefined && (
              <Grid container justifyContent={"flex-end"}>
                <Typography>Show</Typography>
                <AntSwitch
                  checked={sideNavData?.showInCash ? false : true}
                  onChange={() =>
                    handleSideNavChanges("showInCash", !sideNavData?.showInCash)
                  }
                />
                <Typography>Hide in Cash</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className="mt-10">
            <InputLabel className="font-label">Payment Terms</InputLabel>
            <Select disabled value={"Monthly"} fullWidth size="small">
              {[{ label: "Monthly", value: "Monthly" }].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} className="mt-20">
            <InputLabel className="font-label">Month to pay in : </InputLabel>
            <Grid container>
              {sideNavData?.cashTiming?.map((timing, index) => {
                return (
                  <Grid item xs={5.5} className="mr-10 mt-10">
                    <Grid container>
                      <Grid item xs={10}>
                        <InputLabel
                          className="font-label-small"
                          variant="caption"
                        >
                          {index === 0 ? " Same Month" : `Month + ${index}`}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          inputProps={{ min: 0, max: 100 }}
                          value={parseInt(timing || 0).toString()}
                          InputProps={{
                            endAdornment: "%",
                          }}
                          onChange={({ target }) => {
                            handlePaymentTimingChange(
                              index,
                              parseInt(target.value || 0)
                            );
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={2}>
                        {index !== 0 && (
                          <IconButton
                            className="mt-25"
                            color="error"
                            size="small"
                            onClick={() => handleDeleteCashTiming(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid container className="mt-10">
              <Button
                className="text-normal"
                startIcon={<AddCircle fontSize="18px" />}
                onClick={handleAddPaymentTiming}
              >
                Add payment month
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const getAccountMappingContent = () => {
    return (
      <Grid container className="p-10">
        <Grid item xs={12}>
          <TextField
            onChange={({ target }) => {
              setMappingSearch(target.value);
            }}
            value={mappingSearch}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <>
                  {mappingSearch !== "" && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setMappingSearch("")}>
                        <CloseIcon></CloseIcon>
                      </IconButton>
                    </InputAdornment>
                  )}
                  {mappingSearch === "" && <></>}
                  <Search />
                </>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} className="mt-20">
          <RadioGroup className="ml-10">
            {accountMapping
              .filter((mapping) => {
                let regex = new RegExp(
                  `(${_.escapeRegExp(mappingSearch)})`,
                  "gi"
                );
                return regex.test(mapping.text);
              })
              .map((mapping) => {
                if (!mapping.parent) {
                  return (
                    <div style={{ display: "flex" }} key={mapping.id}>
                      <FormControlLabel
                        className={mapping?.parent ? "pl-20" : ""}
                        checked={currentAccountMapping?.id === mapping.id}
                        value={mapping.id}
                        control={<Radio />}
                        label={
                          <span style={{ fontWeight: "bold" }}>
                            {mapping.text}
                          </span>
                        }
                        onClick={({ target }) => {
                          if (selectedMapping === target.value) {
                            setSelectedMapping(null);
                          } else {
                            if (addExpenseItemOpen) {
                              handleExpenseItemChange("mapping", {
                                name: mapping.text,
                                value: mapping.id,
                                type: mapping.type,
                              });
                              handleMappingSelect(target.value);
                            } else {
                            }
                            handleMappingSelect(target.value);
                          }
                        }}
                      />
                      {accountMapping.filter(
                        (child) => child.parent === mapping.id
                      ).length !== 0 && (
                        <IconButton
                          style={{ marginLeft: "auto" }}
                          onClick={() => {
                            handleCollapsibleMapping(mapping.id);
                          }}
                        >
                          {!uncollapsedMapping.includes(mapping.id) && (
                            <KeyboardArrowDown />
                          )}
                          {uncollapsedMapping.includes(mapping.id) && (
                            <KeyboardArrowUp />
                          )}
                        </IconButton>
                      )}
                    </div>
                  );
                } else {
                  return uncollapsedMapping.includes(mapping.parent) ||
                    mappingSearch !== "" ? (
                    <FormControlLabel
                      key={mapping.id}
                      className={mappingSearch === "" ? "pl-20" : ""}
                      checked={currentAccountMapping?.id === mapping.id}
                      value={mapping.id}
                      control={<Radio />}
                      label={mapping.text}
                      onClick={({ target }) => {
                        if (selectedMapping === target.value) {
                          setSelectedMapping(null);
                        } else {
                          if (addExpenseItemOpen) {
                            handleExpenseItemChange("mapping", {
                              name: mapping.text,
                              value: mapping.id,
                              type: mapping.type,
                            });
                          } else {
                          }
                          handleMappingSelect(target.value);
                        }
                      }}
                    />
                  ) : (
                    <Fragment key={mapping.id}></Fragment>
                  );
                }
              })}
          </RadioGroup>
        </Grid>
      </Grid>
    );
  };

  const checkManualInputs = (start, end, sideNavData) => {
    let range = getTimingRange(start, end);
    let manualInputExists = false;
    let sideNavValue = currentAccountMetricForecastId
      ? spreadsheetData.value.find(
          (val) => val.id === currentAccountMetricForecastId
        )
      : sideNavData;
    range.forEach((date) => {
      if (sideNavValue?.[date]?.["manualInput"]) {
        manualInputExists = true;
      }
    });
    return manualInputExists;
  };

  const checkSideNavSave = () => {
    if (currentSideNavTab === 0) {
      let sideNavRange = getTimingRange(
        sideNavData.startDate,
        sideNavData.endDate || durationData?.durationEnd
      );
      let forecastRange = [];
      if (sideNavData.metricType === "Xero") {
        sideNavData.forecastTimings.forEach((forecast) => {
          forecastRange.push(...getTimingRange(forecast.start, forecast.end));
        });
      } else {
        let accountMetrics = spreadsheetData.value.filter(
          (val) => val.metricParentId === sideNavData.id
        );
        accountMetrics.forEach((metric) => {
          metric?.forecastTimings?.forEach((forecast) => {
            forecastRange.push(...getTimingRange(forecast.start, forecast.end));
          });
        });
      }

      if (getArrayDiff(forecastRange, sideNavRange)?.length !== 0) {
        setModalValues({
          ...modalValues,
          message:
            "The dates of the forecasts are not within the range of the start and end dates.",
          singleButton: true,
          confirmText: "Ok",
          modalType: "dateRangeInvalid",
        });
        setModalOpen(true);
        return;
      }
      if (
        checkManualInputs(
          sideNavData.startDate,
          sideNavData.endDate || durationData?.durationEnd,
          sideNavData
        )
      ) {
        setModalValues({
          ...modalValues,
          message:
            "There are currently values that have been manually input in this period. Do you want to retain the manual input values?",
          modalType: "saveMetric",
        });
        setModalOpen(true);
        return;
      } else {
        handleSideNavSave("close", false, sideNavData.metricType);
      }
    } else {
      if (
        sideNavData?.cashTiming?.reduce((a, b) => a + parseInt(b || 0), 0) !==
        100
      ) {
        setModalValues({
          ...modalValues,
          message: "The sum of the cash timing must equal to 100",
          modalType: "cashTimingExceed100",
          singleButton: true,
        });
        setModalOpen(true);
        return;
      } else {
        handleSideNavSave("close", false, sideNavData.metricType);
      }
    }
  };

  const checkForecastSave = (id) => {
    if (
      checkManualInputs(
        currentForecastData.start,
        currentForecastData.end ||
          sideNavData.endDate ||
          durationData.durationEnd,
        sideNavData?.metricType === "Calculation"
          ? currentAccountMetricData
          : sideNavData
      )
    ) {
      setModalValues({
        ...modalValues,
        message:
          "There are currently values that have been manually input in this period. Do you want to retain the manual input values?",
        modalType: "saveForecast",
        idValue: id,
      });
      setModalOpen(true);
    } else {
      handleSaveForecastConfirm(id, false);
    }
  };

  const checkPreviousForecastDate = (id = null) => {
    let sideNavValue = currentAccountMetricForecastId
      ? spreadsheetData?.value?.find(
          (val) => val.id === currentAccountMetricForecastId
        )
      : sideNavData;
    if (sideNavValue?.forecastTimings?.length === 0) {
      return false;
    } else {
      if (
        sideNavValue?.forecastTimings?.map((val) => val.id)?.indexOf(id) === 0
      ) {
        return false;
      }
      let index =
        id === null
          ? sideNavValue?.forecastTimings?.length - 1
          : sideNavValue?.forecastTimings?.map((val) => val.id)?.indexOf(id) -
            1;
      let prevForecast = sideNavValue?.forecastTimings?.[index];

      let newMonth = moment(prevForecast?.end).add(1, "month");
      let monthString = newMonth.year() + "-" + (newMonth.month() + 1);
      if (
        moment(monthString) <=
        moment(sideNavValue?.endDate || durationData?.durationEnd)
      ) {
        return true;
      }
    }
    return false;
  };

  const syncPreviousEndDateToForecast = (id) => {
    let index = 0;
    let sideNavValue = currentAccountMetricForecastId
      ? spreadsheetData.value.find(
          (val) => val.id === currentAccountMetricForecastId
        )
      : sideNavData;
    if (id) {
      index =
        sideNavValue?.forecastTimings?.map((val) => val.id)?.indexOf(id) - 1;
    } else {
      index = sideNavValue?.forecastTimings?.length - 1;
    }

    let prevData = sideNavValue?.forecastTimings[index];
    let newDate = moment(prevData.end).add(1, "month");
    let dateString = newDate.year() + "-" + (newDate.month() + 1);
    handleChangeForecast("startEnd", dateString);
  };

  const handleConfirmModalSubmit = (type, methodSubmitType) => {
    switch (type) {
      case "clearMetric": {
        return methodSubmitType === "confirm"
          ? clearMetric(sideNavData.metricType === "Calculation" ? true : false)
          : () => {};
      }
      case "overlappedDate": {
        return methodSubmitType === "confirm" ? () => {} : () => {};
      }
      case "saveMetric": {
        return methodSubmitType === "confirm"
          ? handleSideNavSave("close", false, sideNavData.metricType)
          : handleSideNavSave("close", true, sideNavData.metricType);
      }
      case "saveAccountMetric": {
        return methodSubmitType === "confirm"
          ? handleSaveAccountMetric(currentAccountMetricData.id)
          : handleSaveAccountMetric(currentAccountMetricData.id, null, true);
      }
      case "saveForecast": {
        return methodSubmitType === "confirm"
          ? handleSaveForecastConfirm(modalValues.idValue, false)
          : handleSaveForecastConfirm(modalValues.idValue, true);
      }
      case "deleteForecast": {
        return methodSubmitType === "confirm"
          ? handleDeleteForecast(modalValues.idValue)
          : () => {};
      }
      case "deleteMetric": {
        return methodSubmitType === "confirm"
          ? handleDeleteAccountMetric(modalValues.idValue)
          : () => {};
      }
      case "metricChange": {
        return methodSubmitType === "confirm"
          ? handleSideNavChanges("metricType", modalValues.idValue, true)
          : () => {};
      }

      case "uniqueAccountName": {
        return methodSubmitType === "confirm" ? () => {} : () => {};
      }
      case "syncToXeroManualMetric": {
        return methodSubmitType === "confirm" ? syncMetricToXero() : () => {};
      }

      default: {
        break;
      }
    }
  };

  const getFormulaMetricList = () => {
    return sideNavData?.accountMetrics?.filter((metric, index) => {
      let usedMetrics = [];
      spreadsheetData.value.forEach((row) => {
        if (row?.formulaId) {
          usedMetrics.push(
            ...(row.id === currentAccountMetricData.id
              ? formulaIdList
              : row?.formulaId)
          );
        }
      });
      let childFormulaList =
        spreadsheetData.value?.find((val) => val.id === metric)?.formulaId ||
        [];

      let cylicFirstLevelTest = !!childFormulaList?.includes(
        currentAccountMetricData.id
      );

      return index === 0 ||
        metric === currentAccountMetricData.id ||
        formulaIdList.includes(metric) ||
        cylicFirstLevelTest ||
        usedMetrics.includes(metric)
        ? false
        : true;
    });
  };

  const handleCloseAddExpenseItem = () => {
    setCurrentExpenseItemValue({
      ...defaultExpenseItemValue,
      startDate: durationData.durationStart,
      endDate: null,
    });
    setAddExpenseItemOpen(false);
    setCurrentSideNavTab(0);
    handleMappingSelect(null);
  };

  const validateExpenseItem = (type) => {
    let condition = true;
    switch (type) {
      case "metricName":
        if (currentExpenseItemValue.metricName === "") condition = false;
        break;
      case "mapping":
        if (currentExpenseItemValue.accountMapping === null) condition = false;
        break;
      default:
        condition = false;
        break;
    }
    return condition;
  };

  const allowMetricAdditionSave = () => {
    let validated =
      validateExpenseItem("metricName") && validateExpenseItem("mapping");
    return validated;
  };

  const handleExpenseItemChange = (type, value) => {
    let updatedData = { ...currentExpenseItemValue };
    switch (type) {
      case "metricName":
        updatedData["metricName"] = value;
        break;
      case "mapping":
        updatedData["accountMapping"] = value;
        break;

      case "startDate":
        updatedData["startDate"] = value;
        break;

      case "endDate":
        updatedData["endDate"] = value;
        break;
      default:
        break;
    }
    setCurrentExpenseItemValue(updatedData);
  };

  const handleAddNewExpenseMetric = () => {
    if (nameList.includes(currentExpenseItemValue.metricName.trim())) {
      setModalOpen(true);
      setModalValues({
        ...modalValues,
        message: "The metric name already exists! Please use a unique name.",
        singleButton: true,
        confirmText: "Ok",
        modalType: "uniqueAccountName",
      });
      return;
    }
    let parentIndex = spreadsheetData.value
      .map((val) => val.id)
      .indexOf(currentExpenseItemValue.parentId);
    let otherChildrenLength = spreadsheetData.value.filter(
      (val) => val.parentId === currentExpenseItemValue.parentId
    ).length;
    let clone = _.cloneDeep(spreadsheetData.value);
    let data = _.cloneDeep(currentExpenseItemValue);
    let expenseId = new ObjectID().toString();
    let expenseRow = {
      id: expenseId,
      metricName: "Expense",
      metricType: "Input",
      valueType: "Currency",
      initialValue: 0,
      forecastTimings: [],
      formulaId: [],
      metricSortOrder: 0,
      parentId: data.parentId,
      subParentId: data.subParentId,
      metricParentId: data.id,
      rowType: ROW_TYPE.METRIC,
    };
    data["accountMetrics"] = [expenseId];
    Object.keys(spreadsheetData.value[0]).forEach((key) => {
      if (!DEFAULT_KEYS.includes(key)) {
        data[key] = {
          disabled: spreadsheetData.value[0][key]["disabled"],
          value: 0,
          manualInput: false,
        };
        expenseRow[key] = {
          disabled: spreadsheetData.value[0][key]["disabled"],
          value: 0,
          manualInput: false,
        };
      }
    });

    clone.splice(parentIndex + otherChildrenLength + 1, 0, data, expenseRow);
    clone = clone.map((row, index) => {
      return { ...row, sortOrder: index + 1 };
    });
    handleSumRows(clone, clone, true);
    setAddExpenseItemOpen(false);
    setCurrentSideNavTab(0);
    handleMappingSelect(null);
  };

  const handleSyncToXeroManualMetric = () => {
    setModalValues({
      ...modalValues,
      message:
        "Are you sure you want to sync the metric with its Xero Account? Metric name will be used to sync with xero account with same name.",
      singleButton: false,
      modalType: "syncToXeroManualMetric",
    });
    setModalOpen(true);
  };
  const syncMetricToXero = () => {
    dispatch(setRefreshing());
    dispatch(
      syncManualMetricToXero({
        spreadSheetId,
        type: "expense",
        rowId: sideNavData.id,
        accountName: sideNavData.metricName?.trim(),
        accountingFirmId,
      })
    );
  };
  const getNewExpenseItemContent = () => {
    return (
      <Grid item xs={12} className="p-10 mt-20">
        <Grid container>
          <Grid item xs={12}>
            <InputLabel className="font-label">Metric Name</InputLabel>
            <TextField
              variant="outlined"
              value={currentExpenseItemValue.metricName}
              onChange={({ target }) => {
                handleExpenseItemChange("metricName", target.value);
              }}
              fullWidth
              size="small"
            ></TextField>
          </Grid>
          <Grid item xs={12} className="mt-20">
            <InputLabel className="font-label">Metric Type</InputLabel>
            <Select
              value={currentExpenseItemValue?.metricType}
              disabled
              fullWidth
              size="small"
              onChange={({ target }) => {
                handleExpenseItemChange("metricType", target.value);
              }}
            >
              {METRIC_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} className="mt-20">
            <InputLabel className="font-label">Value Type</InputLabel>
            <Select
              value={currentExpenseItemValue?.valueType}
              disabled
              fullWidth
              size="small"
              onChange={({ target }) => {
                handleExpenseItemChange("valueType", target.value);
              }}
            >
              {VALUE_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} className="mt-20">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <InputLabel className="font-label">Start Date</InputLabel>

                <DatePicker
                  style={{ width: "100%" }}
                  format={"MMMM YYYY"}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  minDate={dayjs(durationData?.durationStart)}
                  maxDate={dayjs(durationData?.durationEnd)}
                  allowClear={false}
                  size="large"
                  value={dayjs(currentExpenseItemValue?.startDate)}
                  picker="month"
                  onChange={(date) => {
                    handleExpenseItemChange(
                      "startDate",
                      date.year() + "-" + (date.month() + 1)
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className="font-label">
                  End Date (Optional)
                </InputLabel>
                <DatePicker
                  style={{ width: "100%" }}
                  format={"MMMM YYYY"}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  minDate={dayjs(currentExpenseItemValue?.startDate)}
                  maxDate={dayjs(durationData?.durationEnd)}
                  allowClear={false}
                  size="large"
                  value={
                    currentExpenseItemValue?.endDate
                      ? dayjs(currentExpenseItemValue?.endDate)
                      : undefined
                  }
                  status={
                    dayjs(currentExpenseItemValue?.startDate) >
                    dayjs(currentExpenseItemValue?.endDate)
                      ? "error"
                      : ""
                  }
                  picker="month"
                  onChange={(date) => {
                    handleExpenseItemChange(
                      "endDate",
                      date.year() + "-" + (date.month() + 1)
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <ConfirmModal
        modalOpen={modalOpen}
        message={modalValues.message}
        confirmText={modalValues.confirmText}
        singleButton={modalValues.singleButton}
        declineText={modalValues.declineText}
        clearType={modalValues.clearType}
        setModalOpen={setModalOpen}
        modalClose={() => {
          setModalOpen(false);
          setTimeout(() => {
            setModalValues(defaultModalValues);
          }, 100);
        }}
        confirmFunction={() => {
          handleConfirmModalSubmit(modalValues.modalType, "confirm");
          setModalOpen(false);
          setTimeout(() => {
            setModalValues(defaultModalValues);
          }, 100);
        }}
        declineFunction={() => {
          handleConfirmModalSubmit(modalValues.modalType, "decline");
          setModalOpen(false);
          setTimeout(() => {
            setModalValues(defaultModalValues);
          }, 100);
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Comment Section
          </Typography>
        </Box>
      </Modal>

      {/* Add forecast timing modal */}
      <CustomModal
        modalOpen={forecastOpen}
        setModalOpen={handleCloseForecast}
        onClose={handleCloseForecast}
        overflow={"auto"}
        maxWidth={"md"}
      >
        {/* <Modal open={forecastOpen} onClose={handleCloseForecast}>
        <Box sx={style}> */}
        <Grid container px={1}>
          <Grid item xs={8} mb={1}>
            <Typography fontSize={20} fontWeight="bold">
              {forecastModalType === "add" ? "Create " : "Edit "} Forecast
            </Typography>
          </Grid>
          <Grid item xs={4} mb={1}>
            <Grid container justifyContent="flex-end">
              <IconButton onClick={handleCloseForecast}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>

        <Grid container p={2} py={2}>
          <Grid item xs={12} className="mt-10">
            <InputLabel className="mb-10">Timing Name</InputLabel>
            <TextField
              fullWidth
              size="small"
              value={currentForecastData?.name}
              onChange={({ target }) => {
                handleChangeForecast("name", target.value);
              }}
              error={!forecastValidation("name") && forecastMetricSubmitted}
            ></TextField>
          </Grid>
          <Grid item xs={12} className="mt-20">
            <InputLabel className="mb-10">Timing Type</InputLabel>
            <TextField
              fullWidth
              select
              size="small"
              value={currentForecastData?.type}
              error={!forecastValidation("type") && forecastMetricSubmitted}
            >
              {TIMING_TYPES.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => {
                    handleChangeForecast("type", option.value);
                  }}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {checkPreviousForecastDate(
            forecastModalType === "add" ? null : currentForecastData.id
          ) && (
            <Grid item xs={12} className="mt-10">
              <Grid container justifyContent={"flex-end"}>
                <Tooltip
                  title={
                    <p className="f-16">
                      Sync the 'From' date to be the next month after the
                      previous forecast timing end
                    </p>
                  }
                >
                  <IconButton>
                    <Info />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={() => {
                    syncPreviousEndDateToForecast(
                      forecastModalType === "add"
                        ? null
                        : currentForecastData.id
                    );
                  }}
                  variant="outlined"
                >
                  <Sync />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {currentForecastData?.type !== "oneTimeChange" && (
            <Grid item xs={12} className="mt-20">
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <InputLabel className="mb-10">Date Range</InputLabel>
                  <RangePicker
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    format={"MMMM YYYY"}
                    style={{ width: "100%" }}
                    minDate={dayjs(sideNavData?.startDate)}
                    maxDate={
                      sideNavData?.endDate
                        ? dayjs(sideNavData?.endDate)
                        : dayjs(durationData?.durationEnd)
                    }
                    allowClear={false}
                    size="large"
                    value={[
                      dayjs(currentForecastData?.start),
                      currentForecastData?.end
                        ? dayjs(currentForecastData?.end)
                        : null,
                    ]}
                    picker="month"
                    onChange={(date) => {
                      handleChangeForecast("date", [
                        date[0].year() + "-" + (date[0].month() + 1),
                        date[1].year() + "-" + (date[1].month() + 1),
                      ]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {currentForecastData?.type === "oneTimeChange" && (
            <Grid item xs={12} className="mt-20">
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <InputLabel className="mb-10">Start Date</InputLabel>
                  <DatePicker
                    style={{ width: "100%" }}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    format={"MMMM YYYY"}
                    minDate={dayjs(sideNavData?.startDate)}
                    maxDate={
                      sideNavData?.endDate
                        ? dayjs(sideNavData?.endDate)
                        : dayjs(durationData?.durationEnd)
                    }
                    allowClear={false}
                    size="large"
                    value={dayjs(currentForecastData?.start)}
                    picker="month"
                    onChange={(date) => {
                      handleChangeForecast(
                        "start",
                        date?.year() + "-" + (date?.month() + 1)
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="mb-10">End Date (Optional)</InputLabel>
                  <DatePicker
                    style={{ width: "100%" }}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    format={"MMMM YYYY"}
                    minDate={dayjs(currentForecastData?.start)}
                    maxDate={
                      sideNavData?.endDate
                        ? dayjs(sideNavData?.endDate)
                        : dayjs(durationData?.durationEnd)
                    }
                    allowClear
                    size="large"
                    value={
                      currentForecastData?.end
                        ? dayjs(currentForecastData?.end)
                        : null
                    }
                    picker="month"
                    onChange={(date) => {
                      handleChangeForecast(
                        "end",
                        date ? date?.year() + "-" + (date?.month() + 1) : null
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {currentForecastData?.type === "oneTimeChange" && (
            <Grid item xs={12} className="mb-10">
              <Grid container spacing={4} className="mt-5">
                <Grid item xs={6}>
                  <p>
                    Change{" "}
                    <b>
                      {currentAccountMetricForecastId
                        ? currentAccountMetricData?.metricName
                        : sideNavData?.metricName}
                    </b>
                  </p>
                </Grid>
                {currentAccountMetricData?.valueType !== "Decimal" && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      error={
                        !forecastValidation("changeValue") &&
                        forecastMetricSubmitted
                      }
                      value={parseInt(
                        currentForecastData?.changeValue
                      ).toString()}
                      onKeyDown={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength:
                          currentAccountMetricData?.valueType === "Percentage"
                            ? 3
                            : 13,
                      }}
                      InputProps={{
                        startAdornment: <p className="mr-10 m-0">To</p>,
                      }}
                      onChange={({ target }) => {
                        handleChangeForecast(
                          "changeValue",
                          parseInt(target.value || 0)
                        );
                      }}
                    ></TextField>
                  </Grid>
                )}
                {currentAccountMetricData?.valueType === "Decimal" && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      error={
                        !forecastValidation("changeValue") &&
                        forecastMetricSubmitted
                      }
                      value={parseFloat(
                        currentForecastData?.changeValue
                      ).toString()}
                      InputProps={{
                        startAdornment: <p className="mr-10 m-0">To</p>,
                      }}
                      variant="outlined"
                      onKeyDown={(event) => {
                        if (
                          event?.key === "e" ||
                          event?.key === "+" ||
                          event?.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={({ target }) => {
                        if (
                          parseFloat(parseFloat(target.value || 0).toFixed(3)) <
                          1000000000000
                        ) {
                          handleChangeForecast(
                            "changeValue",
                            parseFloat(parseFloat(target.value || 0).toFixed(3))
                          );
                        }
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {currentForecastData?.type === "growthChange" && (
            <Grid item xs={12}>
              <Grid container spacing={4} className="mt-5">
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    value={currentForecastData?.changeType}
                    error={
                      !forecastValidation("changeType") &&
                      forecastMetricSubmitted
                    }
                  >
                    {[
                      { value: "increase", label: "Increase" },
                      { value: "decrease", label: "Decrease" },
                    ].map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={() => {
                          handleChangeForecast("changeType", option.value);
                        }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6} className="mt-5">
                  <b>
                    {" "}
                    {currentAccountMetricForecastId
                      ? currentAccountMetricData?.metricName
                      : sideNavData?.metricName}
                  </b>
                </Grid>
              </Grid>
              <Grid container spacing={4} className="mt-5">
                {(currentAccountMetricData?.valueType !== "Decimal" ||
                  currentForecastData?.changeFormat === "percent") && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      error={
                        !forecastValidation("changeValue") &&
                        forecastMetricSubmitted
                      }
                      value={parseInt(
                        currentForecastData?.changeValue
                      ).toString()}
                      onKeyDown={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength:
                          currentAccountMetricData?.valueType ===
                            "Percentage" ||
                          currentForecastData?.changeFormat === "percent"
                            ? 3
                            : 13,
                      }}
                      InputProps={{
                        startAdornment: <p className="mr-10 m-0">By</p>,
                      }}
                      onChange={({ target }) => {
                        handleChangeForecast(
                          "changeValue",
                          parseInt(target.value || 0)
                        );
                      }}
                    ></TextField>
                  </Grid>
                )}
                {currentAccountMetricData?.valueType === "Decimal" &&
                  currentForecastData?.changeFormat !== "percent" && (
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        error={
                          !forecastValidation("changeValue") &&
                          forecastMetricSubmitted
                        }
                        value={parseFloat(
                          currentForecastData?.changeValue
                        ).toString()}
                        InputProps={{
                          startAdornment: <p className="mr-10 m-0">By</p>,
                        }}
                        variant="outlined"
                        onKeyDown={(event) => {
                          if (
                            event?.key === "e" ||
                            event?.key === "+" ||
                            event?.key === "-"
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onChange={({ target }) => {
                          if (
                            parseFloat(
                              parseFloat(target.value || 0).toFixed(3)
                            ) < 1000000000000
                          ) {
                            handleChangeForecast(
                              "changeValue",
                              parseFloat(
                                parseFloat(target.value || 0).toFixed(3)
                              )
                            );
                          }
                        }}
                      />
                    </Grid>
                  )}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    value={currentForecastData?.changeFormat}
                    error={
                      !forecastValidation("changeFormat") &&
                      forecastMetricSubmitted
                    }
                  >
                    {[
                      { value: "percent", label: "Percent" },
                      { value: "unit", label: "Unit" },
                    ].map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={() => {
                          handleChangeForecast("changeFormat", option.value);
                        }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="mt-20">
                  <TextField
                    className="mb-10"
                    fullWidth
                    size="small"
                    type="number"
                    value={currentForecastData?.changeDuration}
                    error={
                      !forecastValidation("changeDuration") &&
                      forecastMetricSubmitted
                    }
                    InputProps={{
                      startAdornment: <p className="mr-10 m-0">Every</p>,
                      endAdornment: <p className="mr-10 m-0 ml-10">Month(s)</p>,
                      inputProps: { min: 0 },
                    }}
                    onChange={({ target }) => {
                      handleChangeForecast("changeDuration", target.value);
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          )}
          {currentForecastData?.type === "increaseOverTime" && (
            <Grid item xs={12}>
              <Grid container spacing={4} className="mt-5 mb-20">
                <Grid item xs={6} className="mt-5 ">
                  Gradually change{" "}
                  <b>
                    {" "}
                    {currentAccountMetricForecastId
                      ? currentAccountMetricData?.metricName
                      : sideNavData?.metricName}
                  </b>
                </Grid>
                {currentAccountMetricData?.valueType !== "Decimal" && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      error={
                        !forecastValidation("changeValue") &&
                        forecastMetricSubmitted
                      }
                      value={parseInt(
                        currentForecastData?.changeValue
                      ).toString()}
                      onKeyDown={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength:
                          currentAccountMetricData?.valueType === "Percentage"
                            ? 3
                            : 13,
                      }}
                      InputProps={{
                        startAdornment: <p className="mr-10 m-0">To</p>,
                      }}
                      onChange={({ target }) => {
                        handleChangeForecast(
                          "changeValue",
                          parseInt(target.value || 0)
                        );
                      }}
                    ></TextField>
                  </Grid>
                )}
                {currentAccountMetricData?.valueType === "Decimal" && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      error={
                        !forecastValidation("changeValue") &&
                        forecastMetricSubmitted
                      }
                      value={parseFloat(
                        currentForecastData?.changeValue
                      ).toString()}
                      InputProps={{
                        startAdornment: <p className="mr-10 m-0">To</p>,
                      }}
                      variant="outlined"
                      onKeyDown={(event) => {
                        if (
                          event?.key === "e" ||
                          event?.key === "+" ||
                          event?.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={({ target }) => {
                        if (
                          parseFloat(parseFloat(target.value || 0).toFixed(3)) <
                          1000000000000
                        ) {
                          handleChangeForecast(
                            "changeValue",
                            parseFloat(parseFloat(target.value || 0).toFixed(3))
                          );
                        }
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className="mt-20">
            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleCloseForecast}
                variant="contained"
                color="inherit"
                className="mr-10"
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  handleSaveForecast(
                    forecastModalType === "add" ? "" : currentForecastData.id
                  )
                }
                variant="contained"
              >
                Save Forecast
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>

      {/* Add metric modal */}
      <CustomModal
        modalOpen={accountMetricOpen}
        setModalOpen={handleCloseAccountMetric}
        onClose={handleCloseAccountMetric}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid item xs={8} mt={1}>
            <Typography fontSize={20} fontWeight={"bold"}>
              {accountMetricModalType === "add" ? "Create " : "Edit "} Metric
            </Typography>
          </Grid>
          <Grid item xs={4} mt={1}>
            <Grid container justifyContent="flex-end">
              <IconButton onClick={handleCloseAccountMetric}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>

        <Grid container p={2}>
          <Grid item xs={12} className="mt-10">
            <InputLabel className="mb-10">Metric Name</InputLabel>
            <TextField
              fullWidth
              size="small"
              value={currentAccountMetricData?.metricName}
              onChange={({ target }) => {
                handleChangeAccountMetric("metricName", target.value);
              }}
              error={
                !accountMetricValidation(
                  "metricName",
                  currentAccountMetricData
                ) && forecastMetricSubmitted
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} className="mt-20">
            <InputLabel className="mb-10">Metric Type</InputLabel>
            <TextField
              fullWidth
              select
              size="small"
              value={currentAccountMetricData?.metricType}
            >
              {ACCOUNT_METRIC_TYPES.filter((val) => {
                if (
                  sideNavData?.accountMetrics?.indexOf(
                    currentAccountMetricData?.id
                  ) === 0
                ) {
                  return val.value === "Reference" ? false : true;
                } else {
                  return true;
                }
              }).map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => {
                    handleChangeAccountMetric("metricType", option.value);
                  }}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {currentAccountMetricData?.metricType === "Input" && (
            <Grid container>
              <Grid item xs={12} className="mt-20">
                <InputLabel className="mb-10">Value Type</InputLabel>
                <TextField
                  disabled={
                    sideNavData?.accountMetrics?.indexOf(
                      currentAccountMetricData?.id
                    ) === 0
                  }
                  fullWidth
                  select
                  size="small"
                  value={currentAccountMetricData?.valueType}
                >
                  {VALUE_TYPES.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => {
                        handleChangeAccountMetric("valueType", option.value);
                      }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {currentAccountMetricData?.valueType !== "Decimal" && (
                <Grid item xs={12} className="mt-20">
                  <InputLabel>Value </InputLabel>
                  <TextField
                    className="mb-10"
                    fullWidth
                    size="small"
                    value={parseInt(
                      currentAccountMetricData?.initialValue
                    ).toString()}
                    onKeyDown={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength:
                        currentAccountMetricData?.valueType === "Percentage"
                          ? 3
                          : 13,
                    }}
                    onChange={({ target }) => {
                      handleChangeAccountMetric(
                        "initialValue",
                        parseInt(target.value || 0)
                      );
                    }}
                  ></TextField>
                </Grid>
              )}
              {currentAccountMetricData?.valueType === "Decimal" && (
                <Grid item xs={12} className="mt-20">
                  <InputLabel className="mb-10">Value </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    value={parseFloat(
                      currentAccountMetricData?.initialValue
                    ).toString()}
                    variant="outlined"
                    onKeyDown={(event) => {
                      if (
                        event?.key === "e" ||
                        event?.key === "+" ||
                        event?.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onChange={({ target }) => {
                      if (
                        parseFloat(parseFloat(target.value || 0).toFixed(3)) <
                        1000000000000
                      ) {
                        handleChangeAccountMetric(
                          "initialValue",
                          parseFloat(parseFloat(target.value || 0).toFixed(3))
                        );
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {currentAccountMetricData?.metricType === "Calculation" && (
            <Grid container>
              <Grid item xs={12} className="mt-20">
                <InputLabel className="mb-10">Calculation Type</InputLabel>
                <TextField
                  fullWidth
                  select
                  size="small"
                  value={currentAccountMetricData?.calculationType}
                >
                  {CALCULATION_TYPES.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => {
                        handleChangeAccountMetric(
                          "calculationType",
                          option.value
                        );
                      }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} className="mt-20">
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Formula</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent={"flex-end"}>
                      <Button
                        className="text-normal"
                        disabled={
                          getFormulaMetricList().length === 0 || formulaEdit
                        }
                        variant="outlined"
                        startIcon={<Add />}
                        onClick={() => {
                          setFormulaEdit(true);
                          setFormulaIdList(
                            currentAccountMetricData?.formulaId || []
                          );
                        }}
                      >
                        {currentAccountMetricData?.formulaId?.length === 0
                          ? "Add "
                          : "Edit "}
                        formula
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {formulaEdit && (
                  <Grid
                    container
                    className="mt-20"
                    style={{
                      border: "1px grey solid",
                      borderRadius: "5px",
                      padding: 10,
                    }}
                  >
                    <Grid item xs={6}>
                      <InputLabel className="mb-10">Metrics</InputLabel>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        value={[]}
                        SelectProps={{
                          renderValue: () => {},
                          multiple: true,
                        }}
                        disabled={getFormulaMetricList().length === 0}
                      >
                        {getFormulaMetricList()?.map((metric) => {
                          let metricDetail = spreadsheetData.value.find(
                            (val) => val.id === metric
                          );
                          return (
                            <MenuItem
                              key={metric}
                              onClick={() => {
                                let index = formulaIdList.indexOf(metric);
                                if (index !== -1) {
                                  let val = _.cloneDeep(formulaIdList);
                                  val.splice(index, 1);
                                  setFormulaIdList(val);
                                } else {
                                  setFormulaIdList([...formulaIdList, metric]);
                                }
                              }}
                              value={metric}
                            >
                              {metricDetail?.metricName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        className="mt-20 ml-10"
                        disabled={formulaIdList.length <= 1}
                        onClick={() => {
                          setAllowSortOrderFormula(!allowSortOrderFormula);
                        }}
                        color={allowSortOrderFormula ? "primary" : "default"}
                      >
                        <SwapVert />
                      </IconButton>
                    </Grid>
                    {formulaIdList.length !== 0 && (
                      <Grid
                        item
                        className="mt-10"
                        xs={12}
                        style={{
                          border: "1px grey solid",
                          borderRadius: "5px",
                          padding: 10,
                        }}
                      >
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                        >
                          <SortableContext
                            disabled={!allowSortOrderFormula}
                            items={formulaIdList}
                            strategy={rectSortingStrategy}
                          >
                            <SortableGrid columns={3}>
                              {formulaIdList.map((val) => {
                                let data = spreadsheetData.value.find(
                                  (item) => item.id === val
                                );

                                return (
                                  <SortableItem
                                    key={data?.id}
                                    id={data?.id}
                                    name={data?.metricName}
                                    disabled={!allowSortOrderFormula}
                                    onDelete={() => {
                                      let index = formulaIdList.indexOf(
                                        data.id
                                      );
                                      let val = _.cloneDeep(formulaIdList);
                                      val.splice(index, 1);
                                      setFormulaIdList(val);
                                    }}
                                  />
                                );
                              })}
                            </SortableGrid>
                          </SortableContext>
                        </DndContext>
                      </Grid>
                    )}

                    <Grid item xs={12} className="mt-20">
                      <Grid container justifyContent={"flex-end"}>
                        <Button
                          size="small"
                          variant="contained"
                          className="mr-10 text-normal"
                          onClick={() => {
                            setFormulaEdit(false);
                            setFormulaIdList([]);
                            setAllowSortOrderFormula(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            handleChangeAccountMetric(
                              "formulaId",
                              formulaIdList
                            );
                            setFormulaIdList([]);
                            setAllowSortOrderFormula(false);
                            setFormulaEdit(false);
                          }}
                          size="small"
                          variant="contained"
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {currentAccountMetricData?.formulaId?.length !== 0 &&
                  !formulaEdit && (
                    <Grid
                      container
                      className="mt-20"
                      style={{
                        border: "1px grey solid",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                    >
                      {currentAccountMetricData?.formulaId?.map((id, index) => {
                        let metric = spreadsheetData.value.find(
                          (val) => val.id === id
                        );
                        return (
                          <Fragment key={metric?.id}>
                            <Chip
                              className="mr-10"
                              label={metric?.metricName}
                            ></Chip>
                            {currentAccountMetricData?.formulaId?.length - 1 !==
                              index && (
                              <span className=" mr-10 mt-5">
                                {getFormulaSymbol(
                                  currentAccountMetricData?.calculationType
                                )}
                              </span>
                            )}
                          </Fragment>
                        );
                      })}
                    </Grid>
                  )}
              </Grid>
            </Grid>
          )}

          {currentAccountMetricData?.metricType === "Reference" && (
            <Grid container>
              <Grid item xs={12} className="mt-20">
                <InputLabel className="mr-10 text-normal">
                  Reference Tab
                </InputLabel>
                <TextField
                  fullWidth
                  select
                  size="small"
                  value={currentAccountMetricData?.referenceTab}
                >
                  {REFERENCE_TABS.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => {
                        handleChangeAccountMetric("referenceTab", option.value);
                      }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} className="mt-10">
                <InputLabel className="mr-10 text-normal">
                  Reference Row
                </InputLabel>
                <TextField
                  fullWidth
                  select
                  size="small"
                  value={currentAccountMetricData?.referenceId}
                >
                  {spreadSheet[currentAccountMetricData?.referenceTab]?.rows
                    ?.filter((row) => {
                      if (
                        currentAccountMetricData?.referenceTab !== "expense"
                      ) {
                        if (row?.referenceId) {
                          return false;
                        }
                        return true;
                      } else {
                        if (
                          row.rowType === ROW_TYPE.TOTAL_ROW ||
                          row.id === currentAccountMetricData.metricParentId ||
                          row.id === currentAccountMetricData.parentId ||
                          row.id === currentAccountMetricData.subParentId ||
                          row.id === currentAccountMetricData.id ||
                          row.metricParentId ===
                            currentAccountMetricData.metricParentId ||
                          row?.referenceId
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    })
                    ?.map((option) => (
                      <MenuItem
                        sx={{ paddingTop: "5px" }}
                        key={option.id}
                        onClick={() => {
                          handleChangeAccountMetric("referenceId", option.id);
                        }}
                        value={option.id}
                      >
                        {option?.metricName || option?.employeeName}
                        {currentAccountMetricData?.referenceTab !== "people"
                          ? " (" + option.rowType + ")"
                          : ""}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} className="mt-30">
            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleCloseAccountMetric}
                variant="contained"
                className="mr-10"
                color="inherit"
              >
                Cancel
              </Button>
              <Button
                disabled={formulaEdit}
                variant="contained"
                onClick={() => {
                  if (
                    sideNavData?.startDate &&
                    checkManualInputs(
                      sideNavData?.startDate,
                      sideNavData?.endDate || durationData?.durationEnd,
                      currentAccountMetricData
                    )
                  ) {
                    setModalValues({
                      ...modalValues,
                      message:
                        "There are currently values that have been manually input in this period. Do you want to retain the manual input values?",
                      modalType: "saveAccountMetric",
                    });
                    setModalOpen(true);
                    return;
                  } else {
                    handleSaveAccountMetric(
                      accountMetricModalType === "add"
                        ? ""
                        : currentAccountMetricData.id
                    );
                  }
                }}
              >
                Save Metric
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>

      <Drawer anchor={"right"} open={sideNavOpen} onClose={handleCloseSideNav}>
        <Box sx={{ width: "600px", paddingTop: "75px" }}>
          <Grid container className="p-10">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Tabs
                    value={currentSideNavTab}
                    onChange={handleChangeCurrentSideNavTab}
                  >
                    <SideNavStyledTab
                      label="Metric Setting"
                      disabled={sideNavDisableList?.includes(sideNavData?.id)}
                    />
                    <SideNavStyledTab
                      disabled={
                        !sideNavData?.initialSave ||
                        sideNavDisableList?.includes(sideNavData?.id)
                      }
                      label="Cash Timing"
                    />
                    <SideNavStyledTab
                      disabled={
                        !sideNavData?.initialSave ||
                        sideNavDisableList?.includes(sideNavData?.id)
                      }
                      label="Account Mapping"
                    />
                  </Tabs>
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent={"flex-end"}>
                    <IconButton onClick={handleCloseSideNav}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="p-10">
              <Grid container>
                {sideNavDisableList?.includes(sideNavData?.id) && (
                  <Grid item xs={12} className="mt-10 mb-10">
                    <Box sx={{ border: "3px solid red", borderRadius: "10px" }}>
                      <Typography variant="h6" fontWeight={"bold"} p={3}>
                        The metric is disabled as an employee mapping exists for
                        the account !
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography variant="h6" className="font-bold">
                    {sideNavData?.metricName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent={"flex-end"}>
                    {sideNavData?.manualAccountMetric &&
                      !sideNavData?.xeroSynced && (
                        <Tooltip title={<h3>Sync to Xero Account</h3>}>
                          <IconButton
                            onClick={handleSyncToXeroManualMetric}
                            variant="outlined"
                            color="primary"
                          >
                            <Sync />
                          </IconButton>
                        </Tooltip>
                      )}
                    <Button
                      onClick={handleClearMetric}
                      variant="outlined"
                      color="error"
                      disabled={sideNavDisableList?.includes(sideNavData?.id)}
                    >
                      Clear Metric
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {currentSideNavTab === 0 && getMetricSettingContent()}
            {currentSideNavTab === 1 && getCashTimingContent()}
            {currentSideNavTab === 2 && getAccountMappingContent()}
            <Grid item xs={12} className="mt-20 ">
              <Grid container justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCloseSideNav}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    checkSideNavSave();
                  }}
                  variant="contained"
                  color="primary"
                  className="ml-10"
                  disabled={
                    currentSideNavTab === 0
                      ? ((sideNavData?.metricType === "Xero" &&
                          !sideNavData?.priorPeriod) ||
                        allowSortOrder
                          ? true
                          : false) ||
                        sideNavDisableList?.includes(sideNavData?.id)
                      : (currentSideNavTab === 1 ? false : true) ||
                        sideNavDisableList?.includes(sideNavData?.id)
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={addExpenseItemOpen}
        onClose={handleCloseAddExpenseItem}
      >
        <Box sx={{ width: "600px", paddingTop: "75px" }}>
          <Grid container className="p-10">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Tabs
                    value={currentSideNavTab}
                    onChange={handleChangeCurrentSideNavTab}
                  >
                    <SideNavStyledTab label="Metric Setting" />
                    <SideNavStyledTab label="Account Mapping" />
                  </Tabs>
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent={"flex-end"}>
                    <IconButton onClick={handleCloseAddExpenseItem}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {currentSideNavTab === 0 && getNewExpenseItemContent()}
            {currentSideNavTab === 1 && getAccountMappingContent()}
            <Grid item xs={12} className="mt-20 ">
              <Grid container justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCloseAddExpenseItem}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleAddNewExpenseMetric();
                  }}
                  variant="contained"
                  color="primary"
                  className="ml-10"
                  disabled={!allowMetricAdditionSave()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      {spreadsheetData.rows.length !== 0 && (
        <SpreadSheetTable
          rows={_.cloneDeep(spreadsheetData.rows) || []}
          columns={spreadsheetData.columns || []}
          handleColumnResize={handleColumnResize}
          handleCellChange={handleCellChange}
          type="expense"
          handleContextMenu={handleContextMenu}
        ></SpreadSheetTable>
      )}
    </div>
  );
}
