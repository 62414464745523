import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Download, Info, Upload } from "@mui/icons-material";
import { ColorPalette } from "../../../../../constants/colorPalette";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadEmployeeTemplate,
  resetDownloadTemplate,
} from "../../../../../redux/SpreadSheet/SpreadSheetSlice";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import moment from "moment/moment";

const EmployeeDataUpload = ({
  modalOpen,
  setModalOpen,
  setBulkUploadList,
  setRefreshing,
  employeeData,
}) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const { isDownloading, templateDownloadURL } = useSelector(
    (state) => state.spreadsheet
  );
  const [uploadFile, setUploadFile] = useState(null);
  const [importedMsg, setImportMsg] = useState(null);
  const [cellErrorMsg, setCellErrorMsg] = useState(null);

  useEffect(() => {
    if (templateDownloadURL) {
      const link = document.createElement("a");
      link.href = templateDownloadURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(resetDownloadTemplate());
    }
  }, [templateDownloadURL, dispatch]);

  const mapObject = (key) => {
    const obj = {
      "Employee Name": "employeeName",
      "Pay Type": "paymentType",
      "Start Date": "startDate",
      "End Date (optional)": "endDate",
      "Hourly Rate $/hr": "hourlyRate",
      "Annual Salary": "annualSalary",
      "Weekly Hours": "weeklyHours",
      "Tax Free Threshold": "taxFreeThresholdApplied",
      STSL: "stslApplied",
    };
    return obj[key];
  };

  const paymentType = {
    hourly: "hourly",
    salary: "salary",
  };

  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      setUploadFile(file);
      setImportMsg("Please save to import the employee data to spreadsheet!");
    }
  };

  const hanldeIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = () => {
    setUploadFile(null);
    setImportMsg("");
    setCellErrorMsg(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const errorDescriptionTable = () => {
    return (
      <TableContainer sx={{ height: "400px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#6b6d70" }}>Column</TableCell>
              <TableCell sx={{ backgroundColor: "#6b6d70" }}>Rule</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Employee Name</TableCell>
              <TableCell>
                Must be a string and have more than 3 characters.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pay Type</TableCell>
              <TableCell>Must be either "hourly rate" or "salary".</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>
                Must be a valid date (length should not exceed 8 characters).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>End Date</TableCell>
              <TableCell>
                Must be a valid date (length should not exceed 8 characters).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hourly Rate</TableCell>
              <TableCell>Must be a number.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Annual Salary</TableCell>
              <TableCell>Must be a number.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Weekly Hours</TableCell>
              <TableCell>Must be a number.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tax Free Threshold Applied</TableCell>
              <TableCell>
                Must be a boolean value (either "Yes" or "No").
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>STSL Applied</TableCell>
              <TableCell>
                Must be a boolean value (either "Yes" or "No").
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const columnKeyMap = {
    employeeName: "Employee Name",
    paymentType: "Pay Type",
    startDate: "Start Date",
    hourlyRate: "Hourly Rate",
    annualSalary: "Annual Salary",
    weeklyHours: "Weekly Hours",
    taxFreeThresholdApplied: "Tax Free Threshold Applied",
    stslApplied: "STSL Applied",
  };

  const validateRows = (arr) => {
    const employeeNames = arr.map((row) => row.employeeName);
    let existingEmployeeNames = employeeData.map((val) =>
      val.employeeName.trim()
    );
    for (let index = 0; index < arr.length; index++) {
      for (const [key, value] of Object.entries(arr[index])) {
        switch (key) {
          case "employeeName":
            if (typeof value !== "string") {
              setCellErrorMsg(
                `The employee name must be string. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            } else if (value.length < 4) {
              setCellErrorMsg(
                `The length of character must be more than 3 letters. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            } else if (
              employeeNames.some(
                (name, i) => name.trim() === value.trim() && i !== index
              )
            ) {
              setCellErrorMsg(
                `The employee name ${value} which is in row ${index + 2} of ${
                  columnKeyMap[key]
                } column is repeated multiple times`
              );
              return;
            } else if (existingEmployeeNames.includes(value.trim())) {
              setCellErrorMsg(
                `The employee name ${value} which is in row ${index + 2} of ${
                  columnKeyMap[key]
                } column already exists`
              );
              return;
            }
            break;
          case "paymentType":
            if (!paymentType[value]) {
              setCellErrorMsg(
                `Please select the paymentType as hourly rate or salary. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;
          case "startDate":
            if (value.length >= 8) {
              setCellErrorMsg(
                `Please select the valid date. Error found  in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;

          case "hourlyRate":
            if (
              typeof value !== "number" &&
              arr[index]["paymentType"] !== "salary"
            ) {
              setCellErrorMsg(
                `Hourly Rate must be in number. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;
          case "annualSalary":
            if (
              typeof value !== "number" &&
              arr[index]["paymentType"] !== "hourly"
            ) {
              setCellErrorMsg(
                `Annual salary must be in number. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;
          case "weeklyHours":
            if (typeof value !== "number") {
              setCellErrorMsg(
                `Weekly hours must be in number. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;
          case "taxFreeThresholdApplied":
            if (typeof value !== "boolean") {
              setCellErrorMsg(
                `Please select the correct value either yes or no. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;
          case "stslApplied":
            if (typeof value !== "boolean") {
              setCellErrorMsg(
                `Please select the correct value either yes or no. Error found in row ${
                  index + 2
                } of ${columnKeyMap[key]} column`
              );
              return;
            }
            break;
          default:
            break;
        }
      }
    }
    return true;
  };

  const hanldeSubmit = async (e) => {
    const data = await uploadFile.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: null,
    });
    const headers = jsonData[0].slice(0, 9);
    const resultArray = [];

    for (let i = 1; i < jsonData.length; i++) {
      const rowValues = jsonData[i];
      const obj = headers.reduce((object, header, index) => {
        const mappedKey = mapObject(header);
        if (mappedKey) {
          let value = rowValues[index];
          if (mappedKey === "paymentType") {
            value = rowValues[index] === "Salary" ? "salary" : "hourly";
          }
          if (mappedKey === "startDate") {
            value = moment(value).format("YYYY/MM");
          } else if (mappedKey === "endDate") {
            value = value ? moment(value).format("YYYY/MM") : null;
          } else if (value === "Yes") {
            value = true;
          } else if (value === "No") {
            value = false;
          }
          object[mappedKey] = value;
        }
        return object;
      }, {});
      if (
        obj.employeeName !== null &&
        obj.paymentType !== null &&
        obj.stslApplied !== null &&
        obj.taxFreeThresholdApplied !== null &&
        obj.startDate !== "Invalid Date"
      ) {
        resultArray.push(obj);
      }
    }
    const isValid = validateRows(resultArray);
    if (isValid) {
      setBulkUploadList(resultArray);
      setModalOpen(false);
      dispatch(setRefreshing());
    }
  };

  const handleExport = () => {
    dispatch(downloadEmployeeTemplate({ accountingFirmId }));
  };

  return (
    <div>
      <CustomModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onClose={setModalOpen}
        maxWidth="md"
        overflow="auto"
      >
        <Grid container>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Bulk Employee Data</Typography>
            <IconButton
              onClick={() => {
                setModalOpen(false);
                handleRemoveFile();
              }}
            >
              <Close />
            </IconButton>
            <Divider
              sx={{
                width: "100%",
                color: ColorPalette.danger,
                marginTop: "10px",
              }}
            />
          </Grid>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={3}
          >
            <Grid item xs={5.5} mr={1}>
              <Grid>
                <Grid display={"flex"}>
                  <Typography variant="inherit" mb={2}>
                    Import Bulk Data
                  </Typography>
                  <Tooltip
                    className="ml-10"
                    placement="right"
                    title={
                      <p style={{ fontSize: "15px" }}>
                        {errorDescriptionTable()}
                      </p>
                    }
                  >
                    <Info />
                  </Tooltip>
                </Grid>
              </Grid>
              {(fileInputRef && importedMsg) || cellErrorMsg ? (
                <Grid>
                  <Typography variant="subtitle2" mb={1}>
                    {cellErrorMsg && (
                      <Typography color="error"> {cellErrorMsg} </Typography>
                    )}
                  </Typography>
                  <Button variant="outlined" onClick={handleRemoveFile}>
                    Remove File
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <input
                    type="file"
                    accept=".xlsx"
                    ref={fileInputRef}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    endIcon={<Upload />}
                    variant="outlined"
                    onClick={hanldeIconClick}
                    sx={{ textTransform: "none" }}
                  >
                    Import
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={5.5}>
              <Typography variant="inherit" mb={2}>
                Download Employee Template
              </Typography>
              <LoadingButton
                loading={isDownloading}
                endIcon={<Download />}
                variant="contained"
                onClick={handleExport}
                sx={{ textTransform: "none" }}
              >
                Download
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={4}
            gap={3}
          >
            <Button
              onClick={(e) => hanldeSubmit(e)}
              variant="contained"
              disabled={!uploadFile}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
                handleRemoveFile();
              }}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default EmployeeDataUpload;
